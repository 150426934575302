import { BigNumber, ethers } from 'ethers';

export interface ReduxTestState {
  testDataAccounts: Array<BigNumber>;
  testBalance: string | null;
  testContract: ethers.Contract | null;
  testIsAccountLoading: boolean;
  testMintAmount: number;
  testProvider: ethers.providers.Web3Provider | null;
}

const initialState: ReduxTestState = {
  testDataAccounts: [],
  testBalance: null,
  testContract: null,
  testIsAccountLoading: false,
  testMintAmount: 1,
  testProvider: null,
};

export default initialState;
