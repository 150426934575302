import { useState } from 'react';
import { getCurrentTimeInSeconds } from '../utils';
/**
 *  Hook used to handle UI logic surrounding `<LaunchCountdownTimer />`.
 *  This hook returns:
 *  - `isTimerRunning` - a boolean flag that indicates whether the timer is currently running
 *  - `setIsTimerRunning` - a setState function to update the state of `isTimerRunning`
 *  - `getCurrentTimeInSeconds()` - util function for accessing current time in seconds
 *  - `getDuration()` - util function that returns the amount of time left (in seconds) that timer should still display
 *
 * Example usage:
 * ```
 *   const timeToStopTimerInSeconds = useSelector(
 *   ({ launchDate }: RootState) =>
 *     launchDate.timeToStopTimerInSeconds,
 *  );
 *  ...
 *  const {
 *   isTimerRunning,
 *   setIsTimerRunning,
 *   getDuration,
 *   getCurrentTimeInSeconds,
 *  } = useCountdownTimer(timeToStopTimerInSeconds);
 *  ...
 *  <LaunchCountdownTimer
 *    duration={getDuration(timeToStopTimerInSeconds)}
 *    displayCountdownTimer={isTimerRunning}
 *    onComplete={() => setIsTimerRunning(false)}
 *    size={180}
 *    heading="Time to Public Mint"
 *  />
 * ```
 */

export function useCountdownTimer(timeToStopTimerInSeconds: number | null) {
  const [isTimerRunning, setIsTimerRunning] = useState(() =>
    getIsTimerRunning(timeToStopTimerInSeconds),
  );

  return {
    isTimerRunning,
    setIsTimerRunning,
    getCurrentTimeInSeconds,
    getDuration,
  };
}

function getDuration(time?: number | null) {
  return time ? time - getCurrentTimeInSeconds() : 0;
}

function getIsTimerRunning(timeToStopTimerInSeconds: number | null) {
  return timeToStopTimerInSeconds
    ? timeToStopTimerInSeconds - getCurrentTimeInSeconds() > 0
    : false;
}
