export interface WalletState {
  isConnectedToUnsupportedChain: boolean;
  isConnectedToUnsupportedChainOnLogin: boolean;
  isWalletLoading: boolean;
}

const initialState: WalletState = {
  isConnectedToUnsupportedChain: false,
  isConnectedToUnsupportedChainOnLogin: false,
  isWalletLoading: false,
};

export default initialState;
