import { Button, HStack, Input, Tooltip } from '@chakra-ui/react';
import { BlockchainTransactionState, Config } from '@enums';
import { MintEnabledStatus } from '@hooks';
import { EmotionStyles } from '@styles';
import { TransactionState } from '@usedapp/core';
import {
  getIncrementBtnTooltipMessage,
  IncrementButtonTooltipMsgDTO,
} from './utils';

interface MintInputProps {
  account?: string | null;
  customButtonStyles?: EmotionStyles;
  decrementNFTCount: () => void;
  incrementNFTCount: () => void;
  mintEnabledStatus: MintEnabledStatus;
  isSoldOut: boolean;
  isConnectedToUnsupportedChain: boolean;
  isWalletLoading: boolean;
  mintPublicTxStatus: TransactionState;
  mintCount: number;
  numNftsStillAvailable: number | null;
  numNftsPossibleToMintInTx: number | null;
  numNftsWalletCanStillMint: number | null;
  numberMinted: number | null;
  isOwner?: boolean;
}

export const MintInput = ({
  account,
  decrementNFTCount,
  incrementNFTCount,
  mintEnabledStatus,
  isSoldOut,
  isConnectedToUnsupportedChain,
  mintPublicTxStatus,
  mintCount,
  numNftsStillAvailable,
  numNftsPossibleToMintInTx,
  numNftsWalletCanStillMint,
  numberMinted,
  isOwner,
}: MintInputProps) => {
  const mintCountAtMaxValue =
    mintCount >= Config.MaxNftsPerTx_Public ||
    mintCount === numNftsWalletCanStillMint ||
    mintCount === numNftsPossibleToMintInTx;

  const zeroNftsPossibleToMintInTx =
    numNftsPossibleToMintInTx === null || numNftsPossibleToMintInTx <= 0;

  const isOwnerAndMintedMoreThanMax =
    isOwner &&
    numNftsWalletCanStillMint !== null &&
    numNftsWalletCanStillMint < 0;

  const isIncrementCountButtonDisabled =
    isConnectedToUnsupportedChain ||
    mintEnabledStatus === MintEnabledStatus.NoMintingAllowed ||
    mintCountAtMaxValue ||
    mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled ||
    mintPublicTxStatus === BlockchainTransactionState.Mining ||
    numNftsWalletCanStillMint === 0 ||
    zeroNftsPossibleToMintInTx ||
    isOwnerAndMintedMoreThanMax ||
    !account;

  const incrementBtnTooltipMsgDTO: IncrementButtonTooltipMsgDTO = {
    mintCount,
    numNftsWalletCanStillMint,
    numberMinted,
    numNftsStillAvailable,
    numNftsPossibleToMintInTx,
    isSoldOut,
  };

  const incrementButtonTooltipMessage = getIncrementBtnTooltipMessage(
    incrementBtnTooltipMsgDTO,
  );

  const mintCountIsUnderMax =
    numNftsWalletCanStillMint === null || mintCount < numNftsWalletCanStillMint;

  return (
    <HStack marginTop={-24} justifyContent="space-between" minWidth="350px">
      <Button
        size="md"
        borderRadius="3xl"
        variant="outline"
        fontSize="28px"
        borderColor="#FFFFFF"
        color="#FFFFFF"
        _focus={{
          borderColor: '#FFFFFF',
        }}
        _active={{
          backgroundColor: '#FFFFFF',
          boxShadow: '#44337A 0px 3px 0px 1px',
          color: '#44337A',
          borderRadius: '3xl',
        }}
        _hover={{
          backgroundColor: '#44337A',
          borderColor: '#44337A',
        }}
        onClick={decrementNFTCount}
        disabled={
          mintCount <= 1 ||
          mintPublicTxStatus === BlockchainTransactionState.Mining
        }
      >
        -
      </Button>
      <Input
        borderRadius="2xl"
        borderColor="#FFFFFF"
        textAlign="center"
        width="198px"
        height="58px"
        fontSize="28px"
        fontWeight="600"
        value={mintCount}
        color="white"
        min="1"
        max={Config.MaxNftsPerTx_Public.toString()}
        type="number"
        readOnly
        backgroundColor="rgba(226, 232, 240, 0.13)"
        _hover={{
          backgroundColor: 'rgba(226, 232, 240, 0.38)',
          borderColor: '#44337A',
          borderWidth: '1.5px',
        }}
      />
      <Tooltip
        isDisabled={
          !account ||
          mintEnabledStatus === MintEnabledStatus.NoMintingAllowed ||
          mintCountIsUnderMax ||
          numNftsPossibleToMintInTx === null ||
          (mintCount < numNftsPossibleToMintInTx &&
            numNftsPossibleToMintInTx <= 0)
        }
        hasArrow
        label={incrementButtonTooltipMessage}
        bg="gray.300"
        color="black"
        borderRadius="lg"
      >
        <div>
          <Button
            onClick={incrementNFTCount}
            disabled={isIncrementCountButtonDisabled}
            size="md"
            borderRadius="3xl"
            variant="outline"
            fontSize="28px"
            borderColor="#FFFFFF"
            color="#FFFFFF"
            _focus={{
              borderColor: '#FFFFFF',
            }}
            _active={{
              backgroundColor: '#FFFFFF',
              boxShadow: '#44337A 0px 3px 0px 1px',
              color: '#44337A',
            }}
            _hover={{
              backgroundColor: '#44337A',
              borderColor: '#44337A',
            }}
          >
            +
          </Button>
        </div>
      </Tooltip>
    </HStack>
  );
};
