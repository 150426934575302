import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { LCLogo } from '@assets';
import { CalendarIcon } from '@chakra-ui/icons';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import {
  ConnectWalletButton,
  IconCustom,
  SocialIconButton,
  useCountdownTimer,
} from '@components';
import { Config } from '@enums';
import { MintEnabledStatus } from '@hooks';
import { RootState } from '@state';
import { useEthers } from '@usedapp/core';
import { MintDatesModal } from './MintDatesModal';

interface TopNavProps {
  isScrolledToTopOfPage: boolean;
  isMobile: boolean;
  isSmallMobile: boolean;
  isShortScreenDevice: boolean;
  mintEnabledStatus: MintEnabledStatus;
}

export const TopNav = ({
  isScrolledToTopOfPage,
  isMobile,
  isSmallMobile,
  isShortScreenDevice,
  mintEnabledStatus,
}: TopNavProps) => {
  const { account } = useEthers();
  const isWalletLoading = useSelector(
    ({ wallet }: RootState) => wallet.isWalletLoading,
  );

  const buttonOnHoverStyles = isScrolledToTopOfPage
    ? {
        backgroundColor: 'transparent',
        color: '#CBD5E0',
      }
    : {
        backgroundColor: 'transparent',
        color: '#A0AEC0',
      };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isTimerRunning, setIsTimerRunning, getDuration } = useCountdownTimer(
    Config.Date_Public,
  );

  return (
    <Flex
      flexDirection="row"
      alignItems="flex-start"
      justifyContent={'space-between'}
      bg={isScrolledToTopOfPage && !isMobile ? 'transparent' : '#1B1B1B'}
      opacity={isScrolledToTopOfPage && !isMobile ? undefined : '90%'}
      transition="background-color 200ms linear"
      boxShadow={isScrolledToTopOfPage && !isMobile ? undefined : 'lg'}
      position={isMobile ? undefined : 'fixed'}
      top="0"
      left="0"
      right="0"
      width={'100%'}
      zIndex="4000"
      paddingBottom="2"
      paddingTop="6"
      paddingLeft="6"
      paddingRight="8"
    >
      <Fragment>
        {!isMobile && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 40,
            }}
          >
            <Flex alignItems="center" width="100%" marginTop="40px">
              <SocialIconButton
                aria-label={`Check out ${Config.ProjectName} official site`}
                href="https://www.loserclub.io/"
                target="_blank"
                _focus={{ boxShadow: 'none' }}
                filter={
                  isScrolledToTopOfPage
                    ? 'brightness(0) saturate(100%) invert(12%) sepia(63%) saturate(0%) hue-rotate(203deg) brightness(99%) contrast(107%)'
                    : undefined
                }
                icon={
                  <IconCustom
                    src={LCLogo}
                    alt={`Check out ${Config.ProjectName} on Twitter`}
                    aria-label={`Check out ${Config.ProjectName} on Twitter`}
                    width="100%"
                    height="320px"
                  />
                }
                marginLeft="-200px"
                pointerEvents={isScrolledToTopOfPage ? undefined : 'none'}
              />
            </Flex>
          </div>
        )}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: isMobile ? undefined : -40,
          }}
        >
          <Button
            display="flex"
            variant="ghost"
            color={
              isScrolledToTopOfPage && !isMobile
                ? 'rgba(27, 27, 27, 1)'
                : 'white'
            }
            fontSize={18}
            fontWeight="700"
            letterSpacing={'0.24em'}
            _hover={buttonOnHoverStyles}
            _active={{ backgroundColor: 'gray.700' }}
            onClick={(e) => {
              e.preventDefault();
              window.open('https://www.loserclub.io/', '_blank');
            }}
          >
            HOME
          </Button>
        </div>
      </Fragment>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <ConnectWalletButton />
        {mintEnabledStatus !== MintEnabledStatus.PublicMintingEnabled &&
          !isWalletLoading &&
          !isMobile && (
            <div css={{ marginTop: account ? 20 : 26 }}>
              <Button
                css={{ padding: account ? '24px 26px' : '24px 20px' }}
                borderRadius="10px"
                fontSize={account ? '18px' : '14px'}
                leftIcon={<CalendarIcon />}
                backgroundColor={
                  account
                    ? 'rgba(107, 70, 193, 0.60)'
                    : 'rgba(107, 70, 193, 0.20)'
                }
                color="#FFFFFF"
                _hover={{ backgroundColor: 'rgba(97, 70, 193, 0.8)' }}
                _active={{
                  backgroundColor: 'rgba(97, 70, 193, 1)',
                  color: '#FFF',
                }}
                _focus={{ backgroundColor: 'rgba(107, 70, 193, 0.6)' }}
                onClick={onOpen}
              >
                MINT DATES
              </Button>
            </div>
          )}
      </div>
      <MintDatesModal
        isTimerRunning={isTimerRunning}
        setIsTimerRunning={setIsTimerRunning}
        getDuration={getDuration}
        isOpen={isOpen}
        onClose={onClose}
        isMobile={isMobile}
        isSmallMobile={isSmallMobile}
      />
      {mintEnabledStatus !== MintEnabledStatus.PublicMintingEnabled &&
        !isWalletLoading &&
        isMobile &&
        !isShortScreenDevice && (
          <div
            css={{
              marginTop: 30,
              position: 'absolute',
              right: 32,
              top: 84,
              visibility: isOpen ? 'hidden' : undefined,
            }}
          >
            <Button
              css={{ padding: account ? '24px 26px' : '24px 20px' }}
              borderRadius="10px"
              fontSize={account ? '18px' : '14px'}
              leftIcon={<CalendarIcon />}
              backgroundColor={
                account
                  ? 'rgba(107, 70, 193, 0.60)'
                  : 'rgba(107, 70, 193, 0.20)'
              }
              color="#FFFFFF"
              _hover={{ backgroundColor: 'rgba(97, 70, 193, 0.8)' }}
              _active={{
                backgroundColor: 'rgba(97, 70, 193, 1)',
                color: '#FFF',
              }}
              _focus={{ backgroundColor: 'rgba(107, 70, 193, 0.6)' }}
              onClick={onOpen}
            >
              MINT DATES
            </Button>
          </div>
        )}
    </Flex>
  );
};
