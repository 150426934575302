import { forwardRef } from 'react';
import {
  Flower1,
  Flower2,
  Flower2Mobile,
  MaxMintPerTx,
  MintPrice,
  MintYourLoserClub,
  TotalSupply,
} from '@assets';
import { Text, Image } from '@chakra-ui/react';
import { Layout, useGenerateLocalDateTimeFromUnixUTC } from '@components';
import { css } from '@emotion/react';
import { Config } from '@enums';
import { MintEnabledStatus } from '@hooks';
import { Mint, MintAllowlist } from '@mint';
import { mobileLayoutStyles, layoutStyles, flexRow, flexColumn } from '@styles';
import { motion } from 'framer-motion';

interface MintFaqPageProps {
  isMobile: boolean;
  isSmallMobile: boolean;
  isWalletLoading?: boolean;
  mintEnabledStatus: MintEnabledStatus;
}

const mintDescriptionColumnStyles = css(flexColumn, {
  alignItems: 'center',
  marginBottom: 40,
});
const mintDescriptionFinalItemStyles = (
  isMobile: boolean,
  isSmallMobile: boolean,
) =>
  css(flexColumn, {
    maxWidth: setMaxWidthFinalImage(isMobile, isSmallMobile),
  });
const descriptionTextStyles = css({
  fontWeight: 'bold',
  fontSize: 18,
  color: '#FFFFFF',
  lineHeight: '25px',
  letterSpacing: '0.24em',
  marginBottom: 18,
  textAlign: 'center',
});
const outerFlexRowStyles = (isSmallMobile: boolean) =>
  css({
    justifyContent: 'space-around',
    height: !isSmallMobile ? '440px' : undefined,
    flexWrap: !isSmallMobile ? undefined : 'wrap',
  });
const mintInfoFlexColumnStyles = (isSmallMobile: boolean) =>
  css({
    maxWidth: '44%',
    flexWrap: !isSmallMobile ? undefined : 'wrap',
    paddingTop: '30px',
    paddingBottom: isSmallMobile ? 28 : undefined,
  });

export const MintFaqPage = forwardRef<HTMLHeadingElement, MintFaqPageProps>(
  (
    {
      isMobile,
      isSmallMobile,
      isWalletLoading,
      mintEnabledStatus,
    }: MintFaqPageProps,
    ref,
  ) => {
    const allowlistSaleDate = useGenerateLocalDateTimeFromUnixUTC(
      Config.Date_AL,
    );
    const publicSaleDate = useGenerateLocalDateTimeFromUnixUTC(
      Config.Date_Public,
    );

    return (
      <Layout
        customContainerStyles={isMobile ? mobileLayoutStyles : layoutStyles}
        bg="#DADAFF"
        border="32px solid #FFFFFF"
        padding="8"
        paddingX="0"
      >
        <div css={{ scrollMargin: isSmallMobile ? 0 : 260 }} ref={ref}>
          <Image
            src={Flower1}
            alt=""
            marginTop={!isMobile ? '-196px' : '-106px'}
            position="absolute"
            left="-8"
            boxSize={!isMobile ? '300px' : '200px'}
          />
        </div>

        <div css={[flexRow, outerFlexRowStyles(isSmallMobile)]}>
          <div
            css={[
              flexColumn,
              {
                maxWidth: !isSmallMobile ? '50%' : '100%',
                marginRight: !isMobile ? '60px' : '0',
                marginTop: !isMobile ? '0' : '0',
              },
            ]}
          >
            <motion.div
              initial={isMobile ? false : 'hidden'}
              whileInView="visible"
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.6 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <Image
                width="650px"
                height="550px"
                src={MintYourLoserClub}
                alt={`Mint your ${Config.ProjectName}`}
                marginTop={!isMobile ? '-140px' : '-160px'}
                marginBottom={!isMobile ? '-130px' : '-160px'}
              />
            </motion.div>
            <div css={{ minWidth: !isMobile ? 510 : undefined }}>
              <Text
                fontWeight="bold"
                fontSize="22px"
                color="#FFFFFF"
                letterSpacing="0.14em"
                textAlign="center"
                marginBottom={isMobile ? '8' : '2'}
              >
                Pre Sale: {isSmallMobile ? <br /> : undefined}
                {allowlistSaleDate}
              </Text>
              <Text
                fontWeight="bold"
                fontSize="22px"
                color="#FFFFFF"
                letterSpacing="0.14em"
                textAlign="center"
                marginBottom="2"
              >
                Public Sale: {isSmallMobile ? <br /> : undefined}
                {publicSaleDate}
              </Text>
            </div>
            {mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled ? (
              <MintAllowlist
                isWalletLoading={isWalletLoading}
                display="flex"
                size="sm"
                marginLeft="20px"
                borderRadius="sm"
                margin="30px auto 28px"
                _hover={{
                  backgroundColor: '#FF63AD',
                  color: '#FFFFFF',
                }}
                _active={{
                  backgroundColor: 'pink.300',
                  boxShadow: '#ED64A6 0px 3px 0px 1px',
                }}
              />
            ) : (
              <Mint
                isWalletLoading={isWalletLoading}
                display="flex"
                size="sm"
                marginLeft="20px"
                borderRadius="sm"
                margin="30px auto 28px"
                _hover={{
                  backgroundColor: '#FF63AD',
                  color: '#FFFFFF',
                }}
                _active={{
                  backgroundColor: 'pink.300',
                  boxShadow: '#ED64A6 0px 3px 0px 1px',
                }}
              />
            )}
          </div>
          <div css={[flexColumn, mintInfoFlexColumnStyles(isSmallMobile)]}>
            <div css={mintDescriptionColumnStyles}>
              <Text css={descriptionTextStyles}>TOTAL SUPPLY</Text>
              <Image src={TotalSupply} alt="Total Supply" />
            </div>
            <div css={mintDescriptionColumnStyles}>
              <Text css={descriptionTextStyles}>MINT PRICE</Text>
              <Image src={MintPrice} alt="Mint Price" />
            </div>
            <div
              css={[
                !isSmallMobile ? flexColumn : undefined,
                mintDescriptionColumnStyles,
              ]}
            >
              <Text css={descriptionTextStyles}>MAX MINT PER TX</Text>
              <Image
                src={MaxMintPerTx}
                css={mintDescriptionFinalItemStyles(isMobile, isSmallMobile)}
                alt="Max Mint Per Transaction"
              />
            </div>
          </div>
        </div>
        <div>
          <Image
            src={!isMobile ? Flower2 : Flower2Mobile}
            alt=""
            marginTop={!isMobile ? '-160px' : '-150px'}
            position="absolute"
            right="0"
            boxSize={!isMobile ? '400px' : '300px'}
          />
        </div>
      </Layout>
    );
  },
);

function setMaxWidthFinalImage(isMobile: boolean, isSmallMobile: boolean) {
  if (isSmallMobile) {
    return 46;
  }
  if (isMobile) {
    return 75;
  }
  return '100%';
}
