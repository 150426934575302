import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@state/store';
import {
  setIsConnectedToUnsupportedChain,
  setIsConnectedToUnsupportedChainOnLogin,
} from '@state/wallet';
import { useEthers } from '@usedapp/core';
import { getIsCurrentNetworkSupported } from '@utils';

export function useGetCurrentNetwork() {
  const dispatch = useDispatch();
  const { library, chainId, account } = useEthers();

  const [currentNetwork, setCurrentNetwork] = useState('mainnet');
  const [errorConnectingToNetwork, setErrorConnectingToNetwork] =
    useState<Error | null>(null);
  const [isCurrentNetworkSupported, setIsCurrentNetworkSupported] = useState(
    () => getIsCurrentNetworkSupported(chainId),
  );
  const isConnectedToUnsupportedChain = useSelector(
    ({ wallet }: RootState) => wallet.isConnectedToUnsupportedChain,
  );
  const isSupported = getIsCurrentNetworkSupported(chainId);

  useEffect(() => {
    getCurrentNetwork();

    async function getCurrentNetwork() {
      try {
        const detectedNetwork = await library?.detectNetwork();
        setCurrentNetwork(setUsersCurrentNetwork(detectedNetwork?.name));
      } catch (error) {
        console.log('Error retrieving current network: ', error);
        setErrorConnectingToNetwork(error as Error);
        if (error && !account) {
          dispatch(setIsConnectedToUnsupportedChainOnLogin(true));
        }
      }
    }
  }, [dispatch, library, account]);

  useEffect(() => {
    if (!isSupported) {
      dispatch(setIsConnectedToUnsupportedChain(true));
      setIsCurrentNetworkSupported(isSupported);
      return;
    }
    if (isSupported && isConnectedToUnsupportedChain) {
      dispatch(setIsConnectedToUnsupportedChain(false));
    }
    setIsCurrentNetworkSupported(isSupported);
  }, [chainId, dispatch]);

  useEffect(() => {
    window.ethereum?.on('chainChanged', handleChainChange);

    return () => {
      window.ethereum?.removeListener('chainChanged', handleChainChange);
    };

    function handleChainChange() {
      window.location.reload();
    }
  }, []);

  return {
    currentNetwork,
    errorConnectingToNetwork,
    isCurrentNetworkSupported,
  };
}

function setUsersCurrentNetwork(networkName?: string) {
  if (!networkName) {
    return 'mainnet';
  }
  if (networkName === 'homestead') {
    return 'mainnet';
  }
  return networkName;
}
