// import { TokenERC20ABI, TokenERC721ABI } from '@abis';
// import type { TokenERC20 } from '../../../typechain/TokenERC20';
// import type { TokenERC721 } from '../../../typechain/TokenERC721';
import { TokenERC20Data, TokenERC721Data } from '@enums';
import { getContractProvider, getContractSigner } from '@utils';
import { BigNumber } from 'ethers';
import { takeEvery, put, select } from 'redux-saga/effects';
import { selectMintAmount } from './selectors';
import {
  testReadBalanceSuccess,
  testTxTransferSuccess,
  TestTxTransferFetchPayload,
  testReadAccountSuccess,
  testReadAccountFailure,
  testReadAccountEmpty,
  testTxMintSuccess,
  TestReadAccountFetchPayload,
} from './slice';

function* workReadAccountFetchSaga(
  action: TestReadAccountFetchPayload,
): unknown {
  try {
    if (window.ethereum) {
      const currentAccount = action.payload[0];

      if (!currentAccount) {
        yield put(testReadAccountEmpty());
      }

      const accounts = yield window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      yield put(testReadAccountSuccess(accounts));
    }
  } catch (error) {
    console.log('workReadAccountFetchSaga Error: ', error);
    yield put(testReadAccountFailure());
  }
}

function* workReadBalanceFetchSaga(): unknown {
  try {
    if (window.ethereum) {
      const [account] = yield window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      // const contract = getContractProvider<TokenERC20>(
      //   TokenERC20Data.ContractAddress,
      //   TokenERC20ABI,
      // );

      // const balance: BigNumber = yield contract?.balanceOf(account);
      // yield put(testReadBalanceSuccess(balance.toString()));
    }
  } catch (error) {
    console.log('workReadBalanceFetchSaga Error: ', error);
  }
}

function* workTxTransferFetchSaga(action: TestTxTransferFetchPayload): unknown {
  try {
    if (window.ethereum) {
      // Q: is provider, contract, and signer things we should store in redux store?
      //    does it need to be a fresh function call each time to have the latest information
      //    or can we save those values in the store?
      // const contract = getContractSigner<TokenERC20>(
      //   TokenERC20Data.ContractAddress,
      //   TokenERC20ABI,
      // );
      // const transaction = yield contract?.transfer(
      //   action.payload.accountTo,
      //   action.payload.amount,
      // );
      // yield transaction.wait();
      // yield put(
      //   testTxTransferSuccess({
      //     amount: action.payload.amount,
      //     accountTo: action.payload.accountTo,
      //   }),
      // );
    }
  } catch (error) {
    console.log('workTxTransferFetchSaga Error: ', error);
  }
}

function* workTxMintFetchSaga(): unknown {
  try {
    if (window.ethereum) {
      const mintAmount = yield select(selectMintAmount);

      // const contract = getContractSigner<TokenERC721>(
      //   TokenERC721Data.ContractAddress,
      //   TokenERC721ABI,
      // );

      // const transaction = yield contract?.mint(BigNumber.from(mintAmount));
      // yield transaction.wait();
      // yield put(testTxMintSuccess());
    }
  } catch (error) {
    console.log('workTxMintFetchSaga Error: ', error);
  }
}

function* blockchainSaga() {
  yield takeEvery('blockchain/readBalanceFetch', workReadBalanceFetchSaga);
  yield takeEvery('blockchain/txTransferFetch', workTxTransferFetchSaga);
  yield takeEvery('blockchain/readAccountFetch', workReadAccountFetchSaga);
  yield takeEvery('blockchain/txMintFetch', workTxMintFetchSaga);
}

export default blockchainSaga;
