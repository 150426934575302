import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { ButtonProps, Stack, Text } from '@chakra-ui/react';
import {
  useIsMintingEnabled,
  useLoserClubTokenSupply,
  useWalletMintsOfUser,
} from '@hooks';
import { RootState } from '@state';
import { EmotionStyles } from '@styles';
import { useEthers } from '@usedapp/core';
import { FaEthereum } from 'react-icons/fa';
import { useMint } from '../state';
import { MintAllowlistButton } from './MintAllowlistButton';
import { MintAllowlistInput } from './MintAllowlistInput';
import { checkAllowlist } from './utils';

type MintAllowlistProps = ButtonProps & {
  customButtonStyles?: EmotionStyles;
  isIncrementDecrementCountDisplayed?: boolean;
  isWalletLoading?: boolean;
};

export const MintAllowlist = ({
  isIncrementDecrementCountDisplayed = false,
  isWalletLoading = false,
  customButtonStyles,
  ...props
}: MintAllowlistProps) => {
  const [isAllowlisted, setIsAllowlisted] = useState<
    boolean | null | undefined
  >(null);
  const isConnectedToUnsupportedChain = useSelector(
    ({ wallet }: RootState) => wallet.isConnectedToUnsupportedChain,
  );

  const { account } = useEthers();
  const mintEnabledStatus = useIsMintingEnabled();

  const { numNftsWalletCanStillMint, isOwner, numberMinted } =
    useWalletMintsOfUser();
  const { handleAllowlistMint, mintCountData, mintAllowlistData } = useMint();
  const { isSoldOut } = useLoserClubTokenSupply();

  const { mintAllowlistTxStatus } = mintAllowlistData;
  const { decrementNFTCount, incrementNFTCount, mintCount, ethRequiredToMint } =
    mintCountData;

  useEffect(() => {
    if (account) {
      checkAllowlist(account, setIsAllowlisted);
    }
  }, [account, mintEnabledStatus]);

  return isIncrementDecrementCountDisplayed && !isWalletLoading ? (
    <Fragment>
      <MintAllowlistInput
        account={account}
        decrementNFTCount={decrementNFTCount}
        incrementNFTCount={incrementNFTCount}
        isSoldOut={isSoldOut}
        isConnectedToUnsupportedChain={isConnectedToUnsupportedChain}
        isWalletLoading={isWalletLoading}
        isAllowlisted={isAllowlisted}
        mintEnabledStatus={mintEnabledStatus}
        mintAllowlistTxStatus={mintAllowlistTxStatus}
        mintCount={mintCount}
        numNftsWalletCanStillMint={numNftsWalletCanStillMint}
        isOwner={isOwner}
        numberMinted={numberMinted}
      />

      <Text
        textAlign="center"
        fontSize="20px"
        color="white"
        backgroundColor="rgba(255, 99, 173, 0.00)"
        fontWeight="600"
        letterSpacing={isSoldOut ? '0.14em' : '0.2em'}
        marginTop="10px"
        padding={isSoldOut ? '12px 24px 0px' : '8px 24px 0px'}
        borderRadius="2xl"
        display="flex"
        alignItems="center"
        position="relative"
      >
        <FaEthereum
          css={{
            display: 'flex',
            verticalAlign: 'middle',
            marginRight: 8,
          }}
          color="white"
        />{' '}
        <span css={{ marginRight: 6 }}>ETH:</span>{' '}
        <span css={{ minWidth: 58 }}>{ethRequiredToMint}</span>
      </Text>
      <Stack
        padding={{ base: isAllowlisted ? '20px' : '28px', lg: undefined }}
        direction="row"
        spacing={4}
        justifyItems="flex-end"
        marginTop="0"
      >
        <MintAllowlistButton
          account={account}
          handleAllowlistMint={handleAllowlistMint}
          isSoldOut={isSoldOut}
          isConnectedToUnsupportedChain={isConnectedToUnsupportedChain}
          isWalletLoading={isWalletLoading}
          isAllowlisted={isAllowlisted}
          mintEnabledStatus={mintEnabledStatus}
          mintAllowlistTxStatus={mintAllowlistTxStatus}
          mintCount={mintCount}
          numNftsWalletCanStillMint={numNftsWalletCanStillMint}
          isOwner={isOwner}
          customButtonStyles={customButtonStyles}
          numberMinted={numberMinted}
          {...props}
        />
      </Stack>
    </Fragment>
  ) : (
    <Fragment>
      {!isWalletLoading && (
        <MintAllowlistButton
          account={account}
          handleAllowlistMint={handleAllowlistMint}
          isSoldOut={isSoldOut}
          isConnectedToUnsupportedChain={isConnectedToUnsupportedChain}
          isWalletLoading={isWalletLoading}
          isAllowlisted={isAllowlisted}
          mintEnabledStatus={mintEnabledStatus}
          mintAllowlistTxStatus={mintAllowlistTxStatus}
          mintCount={mintCount}
          numNftsWalletCanStillMint={numNftsWalletCanStillMint}
          isOwner={isOwner}
          customButtonStyles={customButtonStyles}
          numberMinted={numberMinted}
          {...props}
        />
      )}
    </Fragment>
  );
};
