import { Dispatch, SetStateAction } from 'react';
import { LoserClubABI } from '@abis';
import { allowlistedAddresses } from '@data';
import { Config, LoserClubContractAddress } from '@enums';
import { MintEnabledStatus } from '@hooks';
import { getContractSigner } from '@utils';
import keccak256 from 'keccak256';
import { MerkleTree } from 'merkletreejs';
import type { LoserClub } from '../../../../typechain/LoserClub';

export interface ALIncrementButtonTooltipMsgDTO {
  isSoldOut: boolean;
  mintCount: number;
  numNftsWalletCanStillMint: number | null;
  numberMinted: number | null;
  isOwner?: boolean;
}

export function getALIncrementBtnTooltipMessage(
  aLIncrementBtnTooltipMsgDTO: ALIncrementButtonTooltipMsgDTO,
) {
  const {
    isSoldOut,
    mintCount,
    numNftsWalletCanStillMint,
    numberMinted,
    isOwner,
  } = aLIncrementBtnTooltipMsgDTO;

  if (isSoldOut) {
    return <p>Sorry, the {Config.ProjectName} collection is sold out!</p>;
  }
  if (numNftsWalletCanStillMint === Config.MaxNftsPerWallet_AL) {
    return (
      <p>
        The max number of NFTs that can be minted per allowlist wallet is{' '}
        {Config.MaxNftsPerWallet_AL}.
      </p>
    );
  }
  if (
    isOwner &&
    numNftsWalletCanStillMint !== null &&
    numNftsWalletCanStillMint < 0
  ) {
    return (
      <p>
        Sorry owner - you can only mint a max of {Config.MaxNftsPerWallet_AL}{' '}
        NFTs from the UI during Allowlist minting - if you would like to mint
        more, please mint directly from the{' '}
        <span css={{ fontWeight: 'bold' }}>mintOwner</span> function on the
        contract.
      </p>
    );
  }
  if (numNftsWalletCanStillMint !== null && numNftsWalletCanStillMint <= 0) {
    return (
      <p>
        You have already minted{' '}
        <span css={{ fontWeight: 'bold' }}>{Config.MaxNftsPerWallet_AL}</span>{' '}
        NFTs with this wallet!
        <br />
        This is the max number of NFTs that can be minted per wallet during
        allowlist.
      </p>
    );
  }
  if (mintCount === numNftsWalletCanStillMint) {
    return (
      <p>
        You have already minted {numberMinted}{' '}
        {numberMinted === 1 ? 'NFT' : 'NFTs'}! You can only mint{' '}
        {numNftsWalletCanStillMint} more...{Config.MaxNftsPerWallet_AL} is the
        max per wallet limit.
      </p>
    );
  }
}

export interface ALMintButtonTooltipMessageDTO {
  isSoldOut: boolean;
  mintCount: number;
  numNftsWalletCanStillMint: number | null;
  numberMinted: number | null;
  isConnectedToUnsupportedChain: boolean;
  mintEnabledStatus: MintEnabledStatus;
  isAllowlisted?: boolean | null;
  account?: string | null;
  isOwner?: boolean;
}

export function getAllowlistMintButtonTooltipMessage(
  alMintButtonTooltipMessageDTO: ALMintButtonTooltipMessageDTO,
) {
  const {
    isSoldOut,
    mintCount,
    numNftsWalletCanStillMint,
    numberMinted,
    isConnectedToUnsupportedChain,
    mintEnabledStatus,
    isAllowlisted,
    account,
    isOwner,
  } = alMintButtonTooltipMessageDTO;

  if (!account) {
    return (
      <p>Please connect your wallet to mint a {Config.ProjectName} NFT.</p>
    );
  }
  if (isSoldOut) {
    return <p>Sorry, the {Config.ProjectName} collection is sold out!</p>;
  }
  if (isConnectedToUnsupportedChain) {
    return <p>Please switch your wallet's network to Ethereum Mainnet.</p>;
  }
  if (mintEnabledStatus === MintEnabledStatus.NoMintingAllowed) {
    return <p>Minting has not started yet</p>;
  }
  if (isAllowlisted === null) {
    return <p>Checking your wallet for allowlist...</p>;
  }
  if (
    isOwner &&
    numNftsWalletCanStillMint !== null &&
    numNftsWalletCanStillMint < 0
  ) {
    return (
      <p>
        Sorry owner - you can only mint a max of {Config.MaxNftsPerWallet_AL}{' '}
        NFTs from the UI during Allowlist minting - if you would like to mint
        more, please mint directly from the{' '}
        <span css={{ fontWeight: 'bold' }}>mintOwner</span> function on the
        contract.
      </p>
    );
  }
  if (!isAllowlisted) {
    return (
      <p>
        Sorry, this wallet has not been allowlisted. Please wait for the public
        mint.
      </p>
    );
  }
  if (Boolean(account) && !isConnectedToUnsupportedChain) {
    const aLIncrementTooltipMsgDTO: ALIncrementButtonTooltipMsgDTO = {
      isSoldOut,
      mintCount,
      numberMinted,
      numNftsWalletCanStillMint,
      isOwner,
    };
    return getALIncrementBtnTooltipMessage(aLIncrementTooltipMsgDTO);
  }
  return <p>Please connect your wallet to mint a {Config.ProjectName} NFT.</p>;
}

export function getMintAllowlistButtonText(
  isSoldOut: boolean,
  mintEnabledStatus: MintEnabledStatus,
  isAllowlisted?: boolean | null,
  account?: string | null,
) {
  if (isSoldOut) {
    return 'SOLD OUT';
  }
  if (mintEnabledStatus === MintEnabledStatus.NoMintingAllowed && account) {
    return 'MINTING NOT STARTED';
  }
  if (isAllowlisted === false || isAllowlisted === undefined) {
    return 'WALLET NOT ALLOWLISTED';
  }
  return 'MINT NOW';
}

export interface IsAllowlistMintButtonTooltipDisplayedDTO {
  numNftsWalletCanStillMint: number | null;
  account?: string | null;
  isConnectedToUnsupportedChain: boolean;
  mintCountIsValid: boolean;
  isAllowlisted?: boolean | null;
  allowlistUserMintedMax: boolean;
  isOwnerTooltipEnabled?: boolean;
}

export function getIsAllowlistTooltipDisplayed(
  isAlTooltipDisplayedDTO: IsAllowlistMintButtonTooltipDisplayedDTO,
) {
  const {
    numNftsWalletCanStillMint,
    account,
    isConnectedToUnsupportedChain,
    mintCountIsValid,
    isAllowlisted,
    allowlistUserMintedMax,
    isOwnerTooltipEnabled,
  } = isAlTooltipDisplayedDTO;
  return (
    numNftsWalletCanStillMint !== null &&
    Boolean(account) &&
    (isConnectedToUnsupportedChain ||
      !mintCountIsValid ||
      !isAllowlisted ||
      allowlistUserMintedMax ||
      isOwnerTooltipEnabled)
  );
}

export async function checkAllowlist(
  account: string,
  setIsAllowlisted: Dispatch<SetStateAction<boolean | null | undefined>>,
) {
  const contract = getContractSigner<LoserClub>(
    LoserClubContractAddress.mainnet,
    LoserClubABI,
  );
  const leaves = allowlistedAddresses.map((x: string) => keccak256(x));
  const tree = new MerkleTree(leaves, keccak256, { sort: true });
  const leaf = keccak256(account);
  const proof = tree.getHexProof(leaf);
  try {
    const isAllowlisted = await contract?.checkAllowlist(proof);
    setIsAllowlisted(isAllowlisted);
  } catch (err) {
    console.log('error in trying to verify allowlist', err);
  }
}
