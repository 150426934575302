import { AppEnvironment } from '@enums';
import { ChainId } from '@usedapp/core';

export function getIsCurrentNetworkSupported(chainId?: ChainId) {
  const environment = process.env.NODE_ENV;

  return environment === AppEnvironment.Development
    ? !!(
        chainId === 1 ||
        chainId === 3 ||
        chainId === 4 ||
        chainId === 5 ||
        chainId === 42
      )
    : chainId === 1;
}
