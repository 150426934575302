import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { formatEther } from '@ethersproject/units';
import { useEtherBalance, useEthers, useSendTransaction } from '@usedapp/core';
import { BigNumber, utils } from 'ethers';

const formatter = new Intl.NumberFormat('en-us', {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

const titleRowStyles = css({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  borderBottom: 'grey 1px solid',
  padding: 16,
});

const inputStyles = css({
  color: 'black',
});

const formatBalance = (balance: BigNumber | undefined) =>
  formatter.format(parseFloat(formatEther(balance ?? BigNumber.from('0'))));

const InputComponent = () => {
  const { account } = useEthers();

  const [amount, setAmount] = useState('0');
  const [address, setAddress] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { sendTransaction, state } = useSendTransaction({
    transactionName: 'Send Ethereum',
  });

  const handleClick = () => {
    setDisabled(true);
    sendTransaction({ to: address, value: utils.parseEther(amount) });
  };

  useEffect(() => {
    if (state.status !== 'Mining') {
      setDisabled(false);
      setAmount('0');
      setAddress('');
    }
  }, [state]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <input
          id={'EthInput'}
          type="number"
          step="0.01"
          value={amount}
          onChange={(e) => setAmount(e.currentTarget.value)}
          min="0"
          disabled={disabled}
          css={inputStyles}
        />
        <div>ETH to:</div>
        <input
          id={'AddressInput'}
          type="text"
          value={address}
          onChange={(e) => setAddress(e.currentTarget.value)}
          disabled={disabled}
          css={inputStyles}
        />
        <button disabled={!account || disabled} onClick={handleClick}>
          Send
        </button>
      </div>
    </div>
  );
};

export const SendEthForm = () => {
  const { account } = useEthers();
  const balance = useEtherBalance(account);

  return (
    <div style={{ padding: 0 }}>
      <div css={titleRowStyles}>
        <h5 style={{ fontSize: 18 }}>Send transaction</h5>
        <div>Your ETH balance: {formatBalance(balance)}</div>
      </div>
      <div style={{ display: 'flex', margin: '32px 0 24px 0' }}>
        <label style={{ marginLeft: '58px' }} htmlFor={'EthInput'}>
          How much?
        </label>
        <label style={{ marginLeft: '240px' }} htmlFor={'AddressInput'}>
          To whom?
        </label>
      </div>
      <InputComponent />
    </div>
  );
};
