import { Component, ComponentType, ErrorInfo, ReactNode } from 'react';
import { css } from '@emotion/react';
import { BasePageLayout } from './BasePageLayout';

interface Props {
  children: ReactNode;
  FallbackComponent?: ComponentType;
}

interface State {
  hasError: boolean;
}

const defaultFallbackStyles = css({
  height: '100vh',
  width: '100vw',
  backgroundColor: '#1A202C',
});

const textStyles = css({
  textAlign: 'center',
  position: 'relative',
  top: '25%',
  fontSize: 30,
  color: 'white',
});

const DefaultFallBack = () => (
  <BasePageLayout>
    <div css={defaultFallbackStyles}>
      <p css={textStyles}>
        Sorry something went wrong with this page. We are working to fix the
        issue.
      </p>
    </div>
  </BasePageLayout>
);

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: only log in dev environment
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const Fallback = this.props.FallbackComponent;
      return Fallback ? <Fallback /> : <DefaultFallBack />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
