import { Dispatch, SetStateAction } from 'react';
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  LaunchCountdownTimer,
  useGenerateLocalDateTimeFromUnixUTC,
} from '@components';
import { Config } from '@enums';

interface MintDatesModalProps {
  isTimerRunning: boolean;
  setIsTimerRunning: Dispatch<SetStateAction<boolean>>;
  getDuration: (time?: number | null) => number;
  isMobile: boolean;
  isSmallMobile: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const MintDatesModal = ({
  isTimerRunning,
  setIsTimerRunning,
  getDuration,
  isOpen,
  onClose,
  isMobile,
  isSmallMobile,
}: MintDatesModalProps) => {
  const allowlistSaleDate = useGenerateLocalDateTimeFromUnixUTC(
    Config.Date_AL,
    true,
  );
  const publicSaleDate = useGenerateLocalDateTimeFromUnixUTC(
    Config.Date_Public,
    true,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        background="rgba(107, 70, 193, 0.95)"
        border="1px"
        borderStyle="solid"
        borderColor="rgba(107, 70, 193, 0.95)"
        borderRadius="3xl"
      >
        <ModalCloseButton
          color="white"
          fontSize="sm"
          padding="12px"
          paddingRight="12px"
          marginRight="10px"
          marginTop="10px"
          _hover={{
            color: 'whiteAlpha.700',
          }}
        />
        <ModalBody pt={2} px={4}>
          <Flex alignItems="center" mb={3}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                padding: 30,
                paddingRight: 50,
                width: '100%',
              }}
            >
              <LaunchCountdownTimer
                customContainerStyles={{
                  alignItems: 'center',
                  marginBottom: 30,
                }}
                customTimerStyles={{
                  marginTop: 20,
                }}
                duration={getDuration(Config.Date_Public)}
                displayCountdownTimer={isTimerRunning}
                onComplete={() => setIsTimerRunning(false)}
                heading="PUBLIC MINT COUNTDOWN"
              />
              <Divider
                color="#FFFFFF"
                margin="20px 10px 50px 10px"
                display="flex"
                flexDirection="row"
                justifyContent="center"
              />
              <Text
                display="flex"
                flexDirection="row"
                justifyContent="center"
                fontWeight="bold"
                fontSize="22px"
                color="#FFFFFF"
                letterSpacing="0.14em"
                textAlign="center"
                marginBottom={isMobile ? '8' : '2'}
              >
                Pre Sale: {isSmallMobile ? <br /> : undefined}
                {allowlistSaleDate}
              </Text>
              <Text
                display="flex"
                flexDirection="row"
                justifyContent="center"
                fontWeight="bold"
                fontSize="22px"
                color="#FFFFFF"
                letterSpacing="0.14em"
                textAlign="center"
                marginBottom="2"
              >
                Public Sale: {isSmallMobile ? <br /> : undefined}
                {publicSaleDate}
              </Text>
            </div>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
