import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import reduxTestStateSagas from './reduxTestState/sagas';
import reduxTestStateSlice from './reduxTestState/slice';
import walletSlice from './wallet/slice';

const rootReducer = combineReducers({
  reduxTestState: reduxTestStateSlice,
  wallet: walletSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

const saga = createSagaMiddleware();

function* rootSaga() {
  yield all([reduxTestStateSagas()]);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: [saga],
});

saga.run(rootSaga);

export default store;
