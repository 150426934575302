import { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';
import { useIsMobile } from '@hooks';

interface BasePageLayoutProps {
  children?: ReactNode;
}

export const BasePageLayout = ({ children }: BasePageLayoutProps) => {
  const isMobile = useIsMobile();
  return (
    <Flex
      flexDirection="column"
      h="100%"
      minWidth={isMobile ? undefined : '100%'}
      maxWidth={isMobile ? '100%' : undefined}
      width="100vw"
      backgroundColor="#D6BCFA"
      overflow="hidden"
    >
      {children}
    </Flex>
  );
};
