import { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LCHeroMobile } from '@assets';
import {
  AccountModal,
  BuiltByDevoptix,
  SocialsLinksBadge,
  WrongNetworkModal,
} from '@components';
import { css } from '@emotion/react';
import { useIsMobile, useIsSmallMobile } from '@hooks';
import { RootState, useAccountModal, useWalletLoading } from '@state';
import './assets/styling/App.css';
import { AppRouter } from './router';

const appContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
});
const builtByDevoptixContainerStyles = css({
  background: 'rgba(27, 27, 27, 0.6)',
  borderRadius: '4px',
});

const floatingSocialLinksStyles = (isMobile: boolean) =>
  css({
    position: 'fixed',
    bottom: isMobile ? '20px' : '120px',
    left: isMobile ? '20px' : '30px',
    background: isMobile ? 'rgba(27, 27, 27, 0.9)' : 'rgba(27, 27, 27, 0.3)',
    borderRadius: '20px',
  });

const mobileBgHeroImage = css({
  backgroundImage: `url('${LCHeroMobile}')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  maxHeight: '100vh',
  minWidth: '100%',
  position: 'absolute',
});

function App() {
  const { isAccountModalOpen, handleAccountModalClose } = useAccountModal();
  const isMobile = useIsMobile();
  const isSmallMobile = useIsSmallMobile();

  const isConnectedToUnsupportedChain = useSelector(
    ({ wallet }: RootState) => wallet.isConnectedToUnsupportedChain,
  );

  const { handleSetWalletLoading } = useWalletLoading();

  const handleOnWalletDisconnect = useCallback(() => {
    handleSetWalletLoading();
    handleAccountModalClose();
  }, [handleSetWalletLoading, handleAccountModalClose]);

  return (
    <Fragment>
      {isSmallMobile && <div css={mobileBgHeroImage}></div>}
      <div css={appContainerStyles}>
        <AppRouter />
        <SocialsLinksBadge
          customContainerStyles={floatingSocialLinksStyles(isMobile)}
          fontSize="30px"
        />
        {!isMobile && (
          <BuiltByDevoptix
            customContainerStyles={builtByDevoptixContainerStyles}
            isMobile={isMobile}
          />
        )}
        {!isConnectedToUnsupportedChain ? (
          <AccountModal
            isOpen={isAccountModalOpen}
            handleOnWalletDisconnect={handleOnWalletDisconnect}
            onClose={handleAccountModalClose}
          />
        ) : (
          <WrongNetworkModal
            isOpen={isAccountModalOpen}
            onClose={handleAccountModalClose}
          />
        )}
      </div>
    </Fragment>
  );
}

export default App;
