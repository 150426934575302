import { LoserClubABI } from '@abis';
import { Contract } from '@ethersproject/contracts';
import { useGetCurrentContractAddress } from '@hooks';
import { useContractFunction } from '@usedapp/core';

export function useMintAllowlistContractCall() {
  const currentContractAddress = useGetCurrentContractAddress();
  const contractInstance = new Contract(currentContractAddress, LoserClubABI);

  const { state: mintAllowlistState, send: mintAllowlist } =
    useContractFunction(contractInstance, 'mintAllowlist', {});

  const mintAllowlistTxStatus = mintAllowlistState.status;
  const errorMessageAllowlist = mintAllowlistState.errorMessage;

  return {
    mintAllowlistState,
    mintAllowlist,
    mintAllowlistTxStatus,
    errorMessageAllowlist,
  };
}
