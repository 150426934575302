import { forwardRef } from 'react';
import {
  Devoptix,
  Galaxies,
  Jay,
  KylieDegener,
  Martina,
  TheTeam,
} from '@assets';
import { Text, Image } from '@chakra-ui/react';
import { Layout } from '@components';
import { css } from '@emotion/react';
import { mobileLayoutStyles, flexRow, flexColumn } from '@styles';
import { motion } from 'framer-motion';

interface TeamPageProps {
  isMobile: boolean;
}

const flexRowStyles = (isMobile: boolean) =>
  css({
    justifyContent: !isMobile ? 'space-around' : 'center',
    alignItems: 'center',
    flexWrap: !isMobile ? undefined : 'wrap',
    marginTop: !isMobile ? '50px' : undefined,
  });

const flexColumnStyles = (isMobile: boolean) =>
  css({
    minWidth: !isMobile ? '20%' : '30%',
    margin: !isMobile ? '0 17.5px' : '15px 40px',
    alignItems: 'center',
  });

const layoutStyles = css({
  paddingTop: 0,
  paddingLeft: 48,
  paddingRight: 48,
});

const mobilePaddingStyles = (isMobile: boolean) =>
  css({
    paddingBottom: isMobile ? 0 : undefined,
  });

const twitterHandleStyles = css({
  color: 'rgba(160, 58, 149, 0.6)',
  '&:hover': {
    transition: 'color 0.1s ease-in-out',
    color: 'rgba(160, 58, 149, 1.0)',
  },
});

const parentVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
  },
};

const childVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

// TODO: add real images for all of below team members
const imagesRow1 = [
  {
    title: (
      <span>
        MARYAM (
        <a
          css={twitterHandleStyles}
          href="https://twitter.com/KylieDegenner"
          target="_blank"
        >
          @KylieDegenner
        </a>
        )
      </span>
    ),
    subTitle: 'Lorem Ipsum',
    image: KylieDegener,
  },
  {
    title: (
      <span>
        JAY (
        <a
          css={twitterHandleStyles}
          href="https://twitter.com/itslawpez"
          target="_blank"
        >
          @itslawpez
        </a>
        )
      </span>
    ),
    subTitle: 'CMO & Cofounder',
    image: Jay,
  },
  {
    title: (
      <span>
        BRIAN (
        <a
          css={twitterHandleStyles}
          href="https://twitter.com/brinarycode"
          target="_blank"
        >
          @brinarycode
        </a>
        )
      </span>
    ),
    subTitle: 'Chief Saxophone Officer',
    image: Galaxies,
  },
];
const imagesRow2 = [
  {
    title: (
      <span>
        MARTINA (
        <a
          css={twitterHandleStyles}
          href="https://twitter.com/MartinaEnwood"
          target="_blank"
        >
          @MartinaEnwood
        </a>
        )
      </span>
    ),
    subTitle: 'Lorem Ipsum',
    image: Martina,
  },
  {
    title: (
      <span>
        DEVOPTIX (
        <a
          css={twitterHandleStyles}
          href="https://twitter.com/devoptix"
          target="_blank"
        >
          @devoptix
        </a>
        )
      </span>
    ),
    subTitle: 'web3 / solidity devs',
    image: Devoptix,
  },
  {
    title: (
      <span>
        JONATHAN (
        <a
          css={twitterHandleStyles}
          href="https://twitter.com/0x0_jmyr"
          target="_blank"
        >
          @0x0_jmyr
        </a>
        )
      </span>
    ),
    subTitle: 'Design',
    image: KylieDegener,
  },
];

export const TeamPage = forwardRef<HTMLHeadingElement, TeamPageProps>(
  ({ isMobile }: TeamPageProps, ref) => (
    <Layout
      customContainerStyles={[
        isMobile ? mobileLayoutStyles : layoutStyles,
        mobilePaddingStyles(isMobile),
      ]}
      bg="#FFFFFF"
    >
      <div css={{ scrollMargin: 100, margin: '40px 0 25px 0' }} ref={ref}>
        <Image src={TheTeam} alt="The Team" />
      </div>
      <motion.div
        initial={isMobile ? false : 'hidden'}
        whileInView="visible"
        viewport={{ once: true }}
        variants={parentVariants}
      >
        <div css={[flexRow, flexRowStyles(isMobile)]}>
          {imagesRow1.map((imageData, i) => (
            <motion.div
              key={`teamRow1-${i}`}
              transition={{ duration: 1.5 }}
              variants={childVariants}
            >
              <div
                css={[flexColumn, flexColumnStyles(isMobile)]}
                key={`${imageData.title}-${i}`}
              >
                <Image
                  src={imageData.image}
                  alt=""
                  minWidth="280px"
                  maxWidth="280px"
                />
                <Text
                  color="#1A1A1A"
                  fontSize={16}
                  fontWeight={600}
                  lineHeight="36px"
                  letterSpacing={'0.20em'}
                  textAlign="center"
                >
                  {imageData.title}
                </Text>
                <Text
                  color="#1A1A1A"
                  fontSize={16}
                  fontWeight={600}
                  lineHeight="36px"
                  letterSpacing={'0.20em'}
                  textAlign="center"
                  marginTop="-6px"
                >
                  {imageData.subTitle}
                </Text>
              </div>
            </motion.div>
          ))}
        </div>

        <div css={[flexRow, flexRowStyles(isMobile)]}>
          {imagesRow2.map((imageData, i) => (
            <motion.div
              key={`teamRow2-${i}`}
              transition={{ duration: 2 }}
              variants={childVariants}
            >
              <div
                css={[flexColumn, flexColumnStyles(isMobile)]}
                key={`${imageData.title}-${i}`}
              >
                <Image
                  src={imageData.image}
                  alt=""
                  minWidth="280px"
                  maxWidth="280px"
                  maxHeight="280px"
                />
                <Text
                  color="#1A1A1A"
                  fontSize={16}
                  fontWeight={600}
                  lineHeight="36px"
                  letterSpacing={'0.20em'}
                  textAlign="center"
                >
                  {imageData.title}
                </Text>
                <Text
                  color="#1A1A1A"
                  fontSize={16}
                  fontWeight={600}
                  lineHeight="36px"
                  letterSpacing={'0.20em'}
                  textAlign="center"
                  marginTop="-6px"
                >
                  {imageData.subTitle}
                </Text>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </Layout>
  ),
);
