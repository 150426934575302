import { useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { css } from '@emotion/react';
import {
  useIsMobile,
  useIsScrolledToTopOfPage,
  useIsSmallMobile,
} from '@hooks';
import { RootState } from '@state';
import { ProgressBar } from '../ProgressBar';
import { ConnectButton } from './ConnectButton';

const walletLoadingProgressBarStyles = (isSmallMobile: boolean) =>
  css({
    minWidth: isSmallMobile ? 180 : 287,
  });

export const ConnectWalletButton = () => {
  const isWalletLoading = useSelector(
    ({ wallet }: RootState) => wallet.isWalletLoading,
  );

  const isMobile = useIsMobile();
  const isSmallMobile = useIsSmallMobile();

  const isScrolledToTopOfPage = useIsScrolledToTopOfPage();

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-end"
      minWidth={isMobile ? undefined : '288px'}
      paddingBottom={isMobile ? 3 : undefined}
    >
      {!isWalletLoading ? (
        <ConnectButton isScrolledToTopOfPage={isScrolledToTopOfPage} />
      ) : (
        <ProgressBar
          customContainerStyles={walletLoadingProgressBarStyles(isSmallMobile)}
          isSmallMobile={isSmallMobile}
        />
      )}
    </Flex>
  );
};
