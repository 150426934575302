import {
  EtherscanLogo,
  Leaves4,
  Leaves5,
  Leaves5Mobile,
  LooksRare,
  OpenSeaLogo,
  TwitterIcon,
  TwitterLoserClubNFT,
  TwitterTheFourHundred,
} from '@assets';
import { Image } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { Config } from '@enums';
import { FaDiscord } from 'react-icons/fa';
import { IconCustom } from './IconCustom';
import { Layout } from './Layout';
import { SocialIconButton } from './SocialIconButton';

interface FooterProps {
  isMobile: boolean;
}

const imageBoxSizeStyles = (isMobile: boolean) => (!isMobile ? '400' : '330');

const imageMarginTopStyles = (isMobile: boolean) =>
  !isMobile ? '-340' : '-240';

const layoutStyles = css({
  paddingTop: 0,
  paddingBottom: 90,
});

const mobileLayoutStyles = css({
  width: '100%',
  padding: '16px 38px 38px 38px',
});

const socialIconsContainer = css({
  display: 'flex',
  alignItems: 'center',
});

const twitterHandlesContainer = (isMobile: boolean) =>
  css({
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    marginTop: 16,
    flexShrink: 0.5,
  });

const twitterHandlesSpacerStyles = css({
  fontWeight: 700,
  letterSpacing: '0.24em',
  color: '#A03A95',
  margin: '-4px 14px 0px 20px',
});

export const Footer = ({ isMobile }: FooterProps) => (
  <Layout
    paddingBottom={isMobile ? '90px !important' : '60px !important'}
    customContainerStyles={isMobile ? mobileLayoutStyles : layoutStyles}
    bg="#FFFFFF"
  >
    <div css={socialIconsContainer}>
      <SocialIconButton
        aria-label="Join our discord"
        href="https://discord.com/"
        target="_blank"
        icon={<FaDiscord />}
        fontSize={30}
        filter="brightness(0) saturate(100%) invert(24%) sepia(79%) saturate(1435%) hue-rotate(277deg) brightness(97%) contrast(84%) opacity(75%)"
      />
      <div>
        <SocialIconButton
          aria-label="View collection on OpenSea"
          href="https://opensea.io/"
          target="_blank"
          icon={
            <IconCustom
              src={OpenSeaLogo}
              alt="Available on OpenSea"
              aria-label="Available on OpenSea"
              filter="brightness(0) saturate(100%) invert(24%) sepia(79%) saturate(1435%) hue-rotate(277deg) brightness(97%) contrast(84%) opacity(75%)"
            />
          }
          fontSize={30}
        />
      </div>
      <SocialIconButton
        aria-label="View contract on Etherscan"
        href="https://etherscan.io/"
        target="_blank"
        icon={
          <IconCustom
            src={EtherscanLogo}
            alt="View contract on Etherscan"
            aria-label="View contract on Etherscan"
            filter="brightness(0) saturate(100%) invert(24%) sepia(79%) saturate(1435%) hue-rotate(277deg) brightness(97%) contrast(84%) opacity(75%)"
          />
        }
        fontSize={30}
      />
      <SocialIconButton
        aria-label="View collection on LooksRare"
        href="https://looksrare.org/"
        target="_blank"
        icon={
          <IconCustom
            src={LooksRare}
            alt="Available on LooksRare"
            aria-label="Available on LooksRare"
            filter="brightness(0) saturate(100%) invert(24%) sepia(79%) saturate(1435%) hue-rotate(277deg) brightness(97%) contrast(84%) opacity(75%)"
          />
        }
      />
      <SocialIconButton
        aria-label={`Follow ${Config.ProjectName} on Twitter`}
        href="https://twitter.com/loserclubreborn"
        target="_blank"
        marginRight="0"
        icon={
          <IconCustom
            src={TwitterIcon}
            alt="Twitter Logo"
            aria-label="Twitter Logo"
            filter="brightness(0) saturate(100%) invert(24%) sepia(79%) saturate(1435%) hue-rotate(277deg) brightness(97%) contrast(84%) opacity(75%)"
          />
        }
        fontSize={30}
      />
    </div>

    <div css={twitterHandlesContainer(isMobile)}>
      <Image
        src={TwitterLoserClubNFT}
        alt={`Follow ${Config.ProjectName} on Twitter`}
        cursor="pointer"
        onClick={(e) => {
          e.preventDefault();
          window.open('https://twitter.com/loserclubreborn', '_blank');
        }}
        css={{ marginBottom: isMobile ? 20 : 0 }}
      />
      {!isMobile && <p css={twitterHandlesSpacerStyles}>|</p>}
      <Image
        src={TwitterTheFourHundred}
        alt="Follow The Four Hundred on Twitter"
        cursor="pointer"
        onClick={(e) => {
          e.preventDefault();
          window.open('https://twitter.com/TheFourHundred_', '_blank');
        }}
      />
    </div>
    <div>
      <Image
        src={Leaves4}
        boxSize={imageBoxSizeStyles(isMobile)}
        alt=""
        marginTop={imageMarginTopStyles(isMobile)}
        position="absolute"
        left={!isMobile ? '50' : '-20'}
        pointerEvents="none"
      />
    </div>
    <div>
      <Image
        src={!isMobile ? Leaves5 : Leaves5Mobile}
        boxSize={imageBoxSizeStyles(isMobile)}
        alt=""
        marginTop={imageMarginTopStyles(isMobile)}
        position="absolute"
        right="0"
        overflow="hidden"
        pointerEvents="none"
      />
    </div>
  </Layout>
);
