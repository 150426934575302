import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './assets/styling/index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { ErrorBoundary } from '@components';
import { MintNFTsProvider } from '@mint';
import { AccountModalProvider } from '@state';
import { ChainId, DAppProvider, Config } from '@usedapp/core';
import App from './App';
import store from './state/store';
import theme from './theme';

const config: Config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]: process.env.REACT_APP_MAINNET_RPC_URL ?? '',
    [ChainId.Ropsten]: process.env.REACT_APP_ROPSTEN_RPC_URL ?? '',
    [ChainId.Kovan]: process.env.REACT_APP_KOVAN_RPC_URL ?? '',
    [ChainId.Rinkeby]: process.env.REACT_APP_RINKEBY_RPC_URL ?? '',
    [ChainId.Goerli]: process.env.REACT_APP_GOERLI_RPC_URL ?? '',
  },
};

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <DAppProvider config={config}>
        <ChakraProvider theme={theme}>
          <ErrorBoundary>
            <MintNFTsProvider>
              <AccountModalProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </AccountModalProvider>
            </MintNFTsProvider>
          </ErrorBoundary>
        </ChakraProvider>
      </DAppProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
