import { forwardRef } from 'react';
import { AboutTheArtists, Jvon1, Leaves1 } from '@assets';
import { Text, Image, Heading } from '@chakra-ui/react';
import { Layout } from '@components';
import { css } from '@emotion/react';
import { layoutStyles, flexRow, flexColumn } from '@styles';
import { motion } from 'framer-motion';

interface TheLoserKingPageProps {
  isMobile: boolean;
  isSmallMobile: boolean;
}

const flexColumnStyles = css(flexColumn, {
  minWidth: '80%',
});
const headingStyles = css({
  scrollMargin: 80,
});
const mobileLayoutStyles = css({
  width: '100%',
  padding: '80px 38px 38px 38px',
});

export const TheLoserKingPage = forwardRef<
  HTMLHeadingElement,
  TheLoserKingPageProps
>(({ isMobile, isSmallMobile }: TheLoserKingPageProps, ref) => (
  <Layout
    customContainerStyles={isMobile ? mobileLayoutStyles : layoutStyles}
    bg="#FFE0E0"
  >
    <div css={{ scrollMargin: 100, margin: '0px 0 25px 0' }} ref={ref}>
      <Image src={AboutTheArtists} alt="About the artists" />
    </div>
    <div
      css={[flexRow, flexRowStyles(isMobile, isSmallMobile), { marginTop: 40 }]}
    >
      <div css={flexColumnStyles}>
        <motion.div
          initial={isMobile ? false : 'hidden'}
          whileInView="visible"
          viewport={{ once: true, amount: 0.6 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <Heading
            css={[headingStyles, { fontWeight: 700, letterSpacing: '0.24em' }]}
            as="h3"
            size="xl"
            color="#A03A95"
            wordBreak="break-all"
            marginBottom="20px"
          >
            J'von - Artist & Cofounder
          </Heading>
          <Image
            src={Jvon1}
            alt="About the artists"
            textAlign="center"
            margin={!isSmallMobile ? '0 40px 0 0' : '0 0 25px 0'}
            // boxSize={!isMobile ? '430px' : undefined}
          />
          <Text
            color="black"
            marginTop="2"
            fontSize={18}
            fontWeight={700}
            lineHeight="36px"
            letterSpacing={'0.08em'}
          >
            “The Loser King” (aka catman aka cool sweater)
          </Text>
        </motion.div>
        <motion.div
          initial={isMobile ? false : 'hidden'}
          whileInView="visible"
          viewport={{ once: true, amount: 0.95 }}
          transition={{ duration: 0.7 }}
          variants={{
            hidden: { opacity: 0, y: 40 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <Text
            color="black"
            marginTop="2"
            fontSize={18}
            fontWeight={300}
            lineHeight="36px"
            letterSpacing={'0.08em'}
          >
            J'von (aka catman aka cool sweater) —{' '}
            <a href="https://twitter.com/itsjvon" target="_blank">
              @itsjvon
            </a>{' '}
            — born in Oakland, California, but raised in Seattle, Washington has
            always had an affinity for visual art, and has been drawing since a
            very young age. He moved around a lot during his school years, and
            that impacted his ability to keep a bunch of friends. His outlet,
            art.
            <br />
            A decade ago, he had left university to pursue music & art, and has
            been fumbling ever since.
            <br />
            J'von has blossomed both socially and creatively, becoming a
            full-fledged musician & visual artist. He focuses on living
            honourably, and taking care of his small family. He wants to show
            his daughter how powerful creativity can be.
            <br />
            A multimedia visual artist, a music producer, and a rapper that
            sings a little, he has worked hard to deserve the opportunity to be
            revered for his contributions to the arts. But this “loser” has not
            (yet) seen the success of his life-long passion.
            <br />
            <i>This</i> is the rise of the loser. <br />
            <a href="https://twitter.com/itsjvon" target="_blank">
              https://twitter.com/itsjvon
            </a>
          </Text>
        </motion.div>
      </div>
    </div>
    <div css={{ alignSelf: 'flex-start' }}>
      <Image
        src={Leaves1}
        alt=""
        marginTop={!isMobile ? '-278px' : '-140px'}
        position="absolute"
        left="-20"
        boxSize={!isMobile ? '670px' : '300px'}
      />
    </div>
  </Layout>
));

function flexRowStyles(isMobile: boolean, isSmallMobile: boolean) {
  return css({
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: getPadding(isMobile, isSmallMobile),
    flexWrap: !isSmallMobile ? undefined : 'wrap',
    textAlign: !isSmallMobile ? undefined : 'center',
  });
}

function getPadding(isMobile: boolean, isSmallMobile: boolean) {
  if (isSmallMobile) {
    return '15px 0 0 15px';
  }
  if (isMobile) {
    return '0 120px';
  }
  return '0 100px';
}
