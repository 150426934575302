import { forwardRef } from 'react';
import { Leaves2, Leaves2Mobile, Leaves3 } from '@assets';
import { Text, Image, Heading } from '@chakra-ui/react';
import { Layout } from '@components';
import { css } from '@emotion/react';
import { mobileLayoutStyles, layoutStyles, flexRow, flexColumn } from '@styles';
import { motion } from 'framer-motion';

interface PlansPageProps {
  isMobile: boolean;
  isSmallMobile: boolean;
}

const headingStyles = css({
  scrollMargin: 80,
});
const flexRowStyles = (isMobile: boolean, isSmallMobile: boolean) =>
  css({
    justifyContent: 'space-around',
    alignItems: 'center',
    height: !isMobile ? undefined : '35%',
    marginTop: !isMobile ? '50px' : '28px',
    padding: !isSmallMobile ? '0 100px' : '15px 0 0 15px',
    flexWrap: !isSmallMobile ? undefined : 'wrap',
    textAlign: !isSmallMobile ? undefined : 'center',
  });
const flexColumnStyles = css(flexColumn, {
  minWidth: '75%',
});
const roadmapImageStyles = css({
  scrollMargin: 100,
  margin: '40px 0 25px 0',
});
const topImagesBoxSizeStyles = (isMobile: boolean) =>
  !isMobile ? '350px' : '250px';
const bottomImagesBoxSizeStyles = (isMobile: boolean) =>
  !isMobile ? '400' : '300';
const marginTopTextStyles = (isMobile: boolean) => (!isMobile ? '2' : '-1');
const marginBottomTextStyles = (isMobile: boolean) => (!isMobile ? '2' : '-2');

export const PlansPage = forwardRef<HTMLHeadingElement, PlansPageProps>(
  ({ isMobile, isSmallMobile }: PlansPageProps, ref) => (
    <Layout
      customContainerStyles={isMobile ? mobileLayoutStyles : layoutStyles}
      bg="#FFFFFF"
    >
      <div css={roadmapImageStyles} ref={ref}>
        {/* note - leaving these images here commented out for now, in case it's helpful to have for whatever images desginer comes with */}
        {/* <Image src={TheRoadmap} alt="The Roadmap" /> */}
      </div>
      <div css={[flexRow, flexRowStyles(isMobile, isSmallMobile)]}>
        {/* <Image
          src={Flower3}
          alt=""
          boxSize={topImagesBoxSizeStyles(isMobile)}
        /> */}
        <div css={flexColumnStyles}>
          <motion.div
            initial={isMobile ? false : 'hidden'}
            whileInView="visible"
            viewport={{ once: true, amount: 0.6 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <Heading
              css={[
                headingStyles,
                { fontWeight: 800, letterSpacing: '0.24em' },
              ]}
              as="h4"
              marginTop={marginTopTextStyles(isMobile)}
              marginBottom={marginBottomTextStyles(isMobile)}
              size="xl"
              color="#FF63AD"
            >
              PLANS
            </Heading>
          </motion.div>
          <motion.div
            initial={isMobile ? false : 'hidden'}
            whileInView="visible"
            viewport={{ once: true, amount: 0.95 }}
            transition={{ duration: 0.7 }}
            variants={{
              hidden: { opacity: 0, x: 60 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <Text
              color="black"
              marginTop="2"
              fontSize={18}
              fontWeight={300}
              lineHeight="36px"
              letterSpacing={'0.08em'}
            >
              Losers are made up of over one hundred and eighty exciting traits.
              Like the successful projects before us, we're committed to
              building something for the long term.
              <br />
              Holding a Loser is your key to being a part of the community
              treasury. This will be seeded with a percentage of the mint, and
              of future secondary sales, which will go straight back into the
              community treasury, where holders will then be able to vote on the
              future of Loser Club.
              <br />
              The NFT community has proven itself to be incredibly talented and
              resourceful. The roadmap therefore, will be decided by our
              community with the team helping to facilitate some of the best
              collabs in the space.
              <br />
              We aren't imposing strict deadlines on ourselves.{' '}
              <span css={{ fontWeight: 'bold' }}>
                We'll underpromise, and over deliver
              </span>
              .
              <br />
              Collaborations and further surprises are already in the pipeline.
            </Text>
          </motion.div>
        </div>
      </div>
      <div>
        <Image
          src={!isMobile ? Leaves2 : Leaves2Mobile}
          alt=""
          marginTop={!isMobile ? '-1000' : '-950'}
          position="absolute"
          right="0"
          boxSize={bottomImagesBoxSizeStyles(isMobile)}
        />
      </div>
      <div>
        <Image
          src={Leaves3}
          alt=""
          marginTop={!isMobile ? '-100' : '-135'}
          position="absolute"
          left={!isMobile ? '-120' : '-100'}
          boxSize={bottomImagesBoxSizeStyles(isMobile)}
        />
      </div>
    </Layout>
  ),
);
