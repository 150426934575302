/* eslint-disable camelcase */
export enum Config {
  Date_Public = 1646514000, // (March 5, 9:00 PM UTC)
  Date_AL = 1646427600, // (March 4, 9:00 PM UTC)
  MaxSupply = 10000,
  MaxNftsPerTx_Public = 5,
  MaxNftsPerTx_AL = 2,
  MaxNftsPerWallet_Public = 5,
  MaxNftsPerWallet_AL = 2,
  MintPrice = 0.088,
  ProjectName = 'Loser Club',
}
