import { useMemo } from 'react';
import { DateTimeFormatOptions } from './models';

export const useGenerateLocalDateTimeFromUnixUTC = (
  timestamp: number,
  isAbbreviatedMonth?: boolean,
) => {
  const localDateTimeFromUnixUTC = useMemo(() => {
    const dateObj = new Date(timestamp * 1000);
    const options: DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    };
    return dateObj.toLocaleString('en-Us', options);
  }, [timestamp]);

  // NOTE - this is for more constricted UI where there's not as much room for full date string
  if (isAbbreviatedMonth) {
    const tempArr = localDateTimeFromUnixUTC.split(' ');
    tempArr[0] = tempArr[0].substring(0, 3);

    return tempArr.join(' ');
  }

  return localDateTimeFromUnixUTC;
};
