import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InfoOutlineIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {
  Button,
  Box,
  Stack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import { formatEther } from '@ethersproject/units';
import { useGetCurrentNetwork, useIsMobile, useIsSmallMobile } from '@hooks';
import { useAccountModal } from '@state';
import { RootState } from '@state/store';
import { useEthers, useEtherBalance } from '@usedapp/core';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { FaEthereum } from 'react-icons/fa';
import Web3Modal from 'web3modal';
import { Identicon } from './Identicon';

interface ConnectButtonProps {
  isScrolledToTopOfPage?: boolean;
}

export function ConnectButton({ isScrolledToTopOfPage }: ConnectButtonProps) {
  const { activate, account, activateBrowserWallet } = useEthers();
  const isMobile = useIsMobile();
  const isSmallMobile = useIsSmallMobile();

  const isWalletLoading = useSelector(
    ({ wallet }: RootState) => wallet.isWalletLoading,
  );

  // note - this effect is here to force reload on accountsChanged event, which is a workaround
  // for new MetaMask issue where MM extension doesn't automatically pop open when user tries to connect
  // TODO: keep eye on this over coming days, merge if issue not fixed by MM
  // useEffect(() => {
  //   function reloadWindow() {
  //     window.location.reload();
  //   }
  //   window.ethereum?.on('accountsChanged', reloadWindow);
  //   return () => {
  //     window.ethereum?.removeListener('accountsChanged', reloadWindow);
  //   };
  // }, [account]);

  useGetCurrentNetwork();
  const etherBalance = useEtherBalance(account);

  const formattedEtherBalance = etherBalance
    ? parseFloat(formatEther(etherBalance)).toFixed(3)
    : null;

  useEffect(() => {
    if (account && !isWalletLoading && !formattedEtherBalance) {
      activateBrowserWallet();
    }
  }, [account, formattedEtherBalance, isWalletLoading, activateBrowserWallet]);

  const isConnectedToUnsupportedChain = useSelector(
    ({ wallet }: RootState) => wallet.isConnectedToUnsupportedChain,
  );

  const { handleAccountModalOpen } = useAccountModal();

  async function handleConnectWallet() {
    const web3Modal = await getWeb3Modal().catch((e) => {
      console.log('Error opening web3Modal: ', e);
    });

    if (!web3Modal) {
      return;
    }

    const connection = await web3Modal
      ?.connect()
      .catch((e) => console.log('Error connecting to web3Modal: ', e));

    if (!connection) {
      return;
    }

    // create copy of the connection object returned from web3modal.connect()
    // so we can access the isMetaMask property inside the Proxy object
    const connectionProxyCopy = Object.assign({}, connection);

    const connector = connectionProxyCopy.isMetaMask
      ? new InjectedConnector({
          ...connection,
          supportedChainIds: [
            1, 3, 4, 5, 25, 42, 137, 250, 1285, 42161, 43114, 1666600000,
          ],
        })
      : setAlternateConnector(connectionProxyCopy);

    function setAlternateConnector(connectionProxyCopy: any) {
      // Note: Once the connector has been activated, the WalletLink SDK instance can be accessed under the .walletLink property.
      return connectionProxyCopy.isCoinbaseWallet ||
        connectionProxyCopy.overrideIsMetaMask
        ? new WalletLinkConnector({
            ...connection,
            supportedChainIds: [
              1, 3, 4, 5, 25, 42, 137, 250, 1285, 42161, 43114, 1666600000,
            ],
            infuraId: process.env.REACT_APP_INFURA_ID,
          })
        : new WalletConnectConnector({
            ...connection,
            supportedChainIds: [
              1, 3, 4, 5, 25, 42, 137, 250, 1285, 42161, 43114, 1666600000,
            ],
            infuraId: process.env.REACT_APP_INFURA_ID,
          });
    }
    activate(connector).catch((e) => console.log('Wallet Activate Error: ', e));
  }

  if (isConnectedToUnsupportedChain) {
    return (
      <Box
        display="flex"
        alignItems="center"
        background="transparent"
        borderRadius="xl"
        py="0"
      >
        <Button
          variant="solid"
          colorScheme="red"
          leftIcon={<WarningTwoIcon />}
          onClick={handleAccountModalOpen}
          border="1px solid transparent"
          _hover={{
            border: '1px',
            borderStyle: 'solid',
            borderColor: 'white',
            backgroundColor: 'red.700',
          }}
          borderRadius="xl"
          m="1px"
          px={3}
          height="38px"
        >
          <Text color="white" fontSize="md" fontWeight="medium" mr="2">
            Wrong Network
          </Text>
        </Button>
      </Box>
    );
  }

  return account ? (
    <Box
      display="flex"
      alignItems="center"
      background={isScrolledToTopOfPage ? '#1B1B1B' : 'gray.700'}
      transition="background-color 200ms linear"
      borderRadius="xl"
      py="0"
    >
      {formattedEtherBalance && !isMobile ? (
        <Box px="3" paddingRight="0px">
          <Text color="white" fontSize="md">
            {formattedEtherBalance} ETH
          </Text>
        </Box>
      ) : null}
      <Box px="3">
        <FaEthereum color="white" />
      </Box>

      <Button
        onClick={handleAccountModalOpen}
        bg={isScrolledToTopOfPage ? '#262626' : 'gray.800'}
        transition="background-color 200ms linear"
        border="1px solid transparent"
        _hover={{
          border: '1px',
          borderStyle: 'solid',
          borderColor: 'blue.400',
          backgroundColor: 'gray.700',
        }}
        borderRadius="xl"
        m="1px"
        px={3}
        height="38px"
      >
        <Text color="white" fontSize="md" fontWeight="medium" mr="2">
          {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length,
            )}`}
        </Text>
        <Identicon />
      </Button>
    </Box>
  ) : (
    <Stack direction="row" spacing={4} justifyItems="flex-end">
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          size="md"
          color="#FFFFFF"
          backgroundColor={isScrolledToTopOfPage ? '#6B46C1' : undefined}
          variant="outline"
          onClick={handleConnectWallet}
          border={isScrolledToTopOfPage ? 'none' : '3px solid #FFFFFF'}
          marginLeft={isSmallMobile ? undefined : '32'}
          borderRadius="sm"
          letterSpacing={'0.24em'}
          fontWeight="700"
          _hover={{
            backgroundColor: '#322659',
            color: '#FFFFFF',
          }}
        >
          CONNECT
        </Button>
        {!isMobile && isScrolledToTopOfPage && (
          <Popover>
            <PopoverTrigger>
              <Button
                borderRadius="10px"
                fontSize="16px"
                marginLeft="32"
                leftIcon={<InfoOutlineIcon />}
                backgroundColor="transparent"
                color="#FFFFFF"
                _hover={{ color: 'black' }}
                _active={{ backgroundColor: 'transparent', color: 'grey' }}
                _focus={{ backgroundColor: 'transparent' }}
              >
                Need Help?
              </Button>
            </PopoverTrigger>
            <PopoverContent
              color="white"
              bg="gray.600"
              borderColor="gray.600"
              marginRight="20px"
              padding="6px 0px 10px 10px"
            >
              <PopoverArrow bg="gray.600" borderColor="gray.600" />
              <PopoverCloseButton />
              <PopoverHeader
                pt={3}
                fontWeight="bold"
                border="0"
                fontSize="20px"
              >
                Wallet Connect Tips
              </PopoverHeader>
              <PopoverBody>
                <p>
                  Be sure to{' '}
                  <span css={{ fontWeight: 700 }}>
                    only have one wallet extension active before connecting.
                  </span>{' '}
                  <br />
                  <br />
                  You will have issues connecting if you have both MetaMask and
                  Coinbase wallet extensions active at the same time. This is a
                  known issue with Coinbase wallet.
                  <br />
                  <br />
                  To Deactivate an extension - go to your{' '}
                  <a href="chrome://extensions/" target="_blank">
                    <span
                      css={{ fontWeight: 700, textDecoration: 'underline' }}
                    >
                      Chrome extensions
                    </span>
                  </a>{' '}
                  ➡ toggle MetaMask or Coinbase Wallet off 👍
                </p>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </div>
    </Stack>
  );
}

async function getWeb3Modal() {
  const web3Modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: false,
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID,
        },
      },
    },
  });
  return web3Modal;
}

function reloadWindow() {
  window.location.reload();
}
