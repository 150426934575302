import { ReactNode } from 'react';
import { Flex, ResponsiveValue, FlexProps } from '@chakra-ui/react';
import { EmotionStyles } from '@styles';

type LayoutProps = FlexProps & {
  children?: ReactNode;
  customContainerStyles?: EmotionStyles;
  bgImage?: ResponsiveValue<any>;
  bg?: string;
};

export const Layout = ({
  bgImage,
  children,
  customContainerStyles,
  bg = 'purple.200',
  ...props
}: LayoutProps) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    bg={bg}
    padding="8"
    paddingX="72"
    css={customContainerStyles}
    bgImage={bgImage}
    {...props}
  >
    {children}
  </Flex>
);
