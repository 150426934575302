import { useSelector } from 'react-redux';
import { LoserClubContractAddress } from '@enums';
import { RootState } from '@state';
import { useGetCurrentNetwork } from './useGetCurrentNetwork';

export function useGetCurrentContractAddress() {
  const { currentNetwork, isCurrentNetworkSupported } = useGetCurrentNetwork();

  const isConnectedToUnsupportedChain = useSelector(
    ({ wallet }: RootState) => wallet.isConnectedToUnsupportedChain,
  );

  const shouldUseMainnetFallbackAddress =
    isConnectedToUnsupportedChain ||
    currentNetwork === 'unknown' ||
    !isCurrentNetworkSupported;

  return shouldUseMainnetFallbackAddress
    ? LoserClubContractAddress.mainnet
    : LoserClubContractAddress[
        currentNetwork as keyof typeof LoserClubContractAddress
      ];
}
