import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { LoserClubABI } from '@abis';
import { allowlistedAddresses } from '@data';
import { Config, EstimateGasStatus, LoserClubContractAddress } from '@enums';
import {
  MintEnabledStatus,
  useBlockchainToast,
  useIsMintingEnabled,
  useLoserClubTokenSupply,
  useWalletMintsOfUser,
} from '@hooks';
import { TransactionState, TransactionStatus } from '@usedapp/core';
import { getContractSigner } from '@utils';
import { utils, BigNumber } from 'ethers';
import keccak256 from 'keccak256';
import { MerkleTree } from 'merkletreejs';
import type { LoserClub } from '../../../../typechain/LoserClub';
import { useMintAllowlistContractCall } from './hooks/useMintAllowlistContractCall';
import { useMintContractCall } from './hooks/useMintContractCall';
import { useMintCount } from './hooks/useMintCount';

interface MintNFTsContext {
  mintCountData: {
    mintCount: number;
    decrementNFTCount: () => void;
    incrementNFTCount: () => void;
    setMintCount: React.Dispatch<React.SetStateAction<number>>;
    ethRequiredToMint: string;
    setEthRequiredToMint: React.Dispatch<React.SetStateAction<string>>;
  };
  mintPublicData: {
    mintPublicState: TransactionStatus;
    mintPublic: (...args: any[]) => Promise<void>;
    mintPublicTxStatus: TransactionState;
    errorMessage?: string;
  };
  mintAllowlistData: {
    mintAllowlistState: TransactionStatus;
    mintAllowlist: (...args: any[]) => Promise<void>;
    mintAllowlistTxStatus: TransactionState;
    errorMessage?: string;
  };
  handlePublicMint: (
    mintCount: number,
    mintEnabledStatus: MintEnabledStatus,
  ) => void;
  handleAllowlistMint: (
    mintCount: number,
    mintEnabledStatus: MintEnabledStatus,
    account?: string | null,
  ) => void;
  estimateGasStatus: EstimateGasStatus;
}

interface MintNFTsProviderProps {
  children: ReactElement;
}

const MintNFTsContext = React.createContext<MintNFTsContext>(
  {} as MintNFTsContext,
);

MintNFTsContext.displayName = 'MintNFTsContext';

export const MintNFTsProvider = ({ children }: MintNFTsProviderProps) => {
  const mintEnabledStatus = useIsMintingEnabled();
  const [estimateGasStatus, setEstimateGasStatus] = useState<EstimateGasStatus>(
    EstimateGasStatus.NoError,
  );

  const getMaxMintCount = () => {
    if (mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled) {
      return Config.MaxNftsPerTx_AL;
    }
    if (mintEnabledStatus === MintEnabledStatus.PublicMintingEnabled) {
      return Config.MaxNftsPerTx_Public;
    }
    return 1;
  };
  const mintCountData = useMintCount(1, getMaxMintCount());
  const { ethRequiredToMint, mintCount, setMintCount } = mintCountData;

  const userWalletMintsData = useWalletMintsOfUser();

  const { numNftsWalletCanStillMint } = userWalletMintsData;

  const { isSoldOut, numNftsPossibleToMintInTx } = useLoserClubTokenSupply();

  const mintPublicData = useMintContractCall();
  const { mintPublicState, mintPublic } = mintPublicData;

  const mintAllowlistData = useMintAllowlistContractCall();
  const { mintAllowlistState, mintAllowlist } = mintAllowlistData;

  const mintState =
    mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled
      ? mintAllowlistState
      : mintPublicState;

  const userWalletData = {
    numNftsPossibleToMintInTx,
    ...userWalletMintsData,
  };

  const getBlockchainToastMintCount = () => {
    if (
      mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled &&
      numNftsWalletCanStillMint === 0
    ) {
      return Config.MaxNftsPerWallet_AL;
    }
    if (
      mintEnabledStatus === MintEnabledStatus.PublicMintingEnabled &&
      numNftsWalletCanStillMint === 0
    ) {
      return Config.MaxNftsPerWallet_Public;
    }
    return mintCount;
  };

  const blockchainToastMintCount = getBlockchainToastMintCount();

  const mintSuccessNFTsMessage =
    blockchainToastMintCount === 1 ? 'NFT' : 'NFTs';

  const transactionStatus = useBlockchainToast(
    mintState,
    mintEnabledStatus,
    userWalletData,
    estimateGasStatus,
    `You minted ${blockchainToastMintCount} ${Config.ProjectName} ${mintSuccessNFTsMessage}!`,
  );

  const handlePublicMint = useCallback(
    async (mintCount: number, mintEnabledStatus: MintEnabledStatus) => {
      if (
        isSoldOut ||
        mintEnabledStatus === MintEnabledStatus.NoMintingAllowed ||
        numNftsPossibleToMintInTx === null
      ) {
        setMintCount(0);
        return;
      }
      if (
        mintCount < 1 ||
        mintCount > Config.MaxNftsPerTx_Public ||
        mintCount > numNftsPossibleToMintInTx
      ) {
        setMintCount(1);
        return;
      }
      if (mintEnabledStatus === MintEnabledStatus.PublicMintingEnabled) {
        const contract = getContractSigner<LoserClub>(
          LoserClubContractAddress.mainnet,
          LoserClubABI,
        );

        const gasLimit: any = await contract?.estimateGas
          .mintPublic(mintCount, {
            value: utils.parseEther(ethRequiredToMint),
          })
          .catch((err: Error) => {
            console.log('err: ', err);
            if (err.message.includes('insufficient funds')) {
              setEstimateGasStatus(EstimateGasStatus.InsufficientFunds);
            } else {
              setEstimateGasStatus(EstimateGasStatus.CannotEstimateGas);
            }
          });

        const newGasLimit = Math.round(parseInt(gasLimit) * 1.2);

        mintPublic(mintCount, {
          value: utils.parseEther(ethRequiredToMint),
          gasLimit: newGasLimit,
        });
      }

      // TODO: comment this back in if useDapp fixes transaction state
      // to update in real-time in future version

      // if (
      //   transactionStatus?.toastStatus &&
      //   transactionStatus?.id &&
      //   !toast.isActive(transactionStatus?.id)
      // ) {
      //   toast({
      //     id: transactionStatus?.id,
      //     title: transactionStatus?.toastTitle,
      //     description: transactionStatus?.toastDescription,
      //     status: transactionStatus?.toastStatus,
      //     duration: 6000,
      //     isClosable: true,
      //   });
      // }
    },
    [
      mintPublic,
      numNftsPossibleToMintInTx,
      setMintCount,
      isSoldOut,
      ethRequiredToMint,
    ],
  );

  const handleAllowlistMint = useCallback(
    async (
      mintCount: number,
      mintEnabledStatus: MintEnabledStatus,
      account?: string | null,
    ) => {
      if (
        isSoldOut ||
        mintEnabledStatus === MintEnabledStatus.NoMintingAllowed ||
        numNftsWalletCanStillMint === null
      ) {
        setMintCount(0);
        return;
      }
      if (
        mintCount < 1 ||
        mintCount > Config.MaxNftsPerTx_AL ||
        mintCount > numNftsWalletCanStillMint
      ) {
        setMintCount(1);
        return;
      }

      if (
        mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled &&
        account
      ) {
        const leaves = allowlistedAddresses.map((x: string) => keccak256(x));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        const leaf = keccak256(account);
        const proof = tree.getHexProof(leaf);

        const contract = getContractSigner<LoserClub>(
          LoserClubContractAddress.mainnet,
          LoserClubABI,
        );

        const gasLimit: any = await contract?.estimateGas
          .mintAllowlist(mintCount, proof, {
            value: utils.parseEther(ethRequiredToMint),
          })
          .catch((err: Error) => {
            if (err.message.includes('insufficient funds')) {
              setEstimateGasStatus(EstimateGasStatus.InsufficientFunds);
            } else {
              setEstimateGasStatus(EstimateGasStatus.CannotEstimateGas);
            }
          });

        const newGasLimit = Math.round(parseInt(gasLimit) * 1.2);

        mintAllowlist(mintCount, proof, {
          value: utils.parseEther(ethRequiredToMint),
          gasLimit: newGasLimit,
        });
      }
    },
    [
      ethRequiredToMint,
      mintAllowlist,
      numNftsWalletCanStillMint,
      setMintCount,
      isSoldOut,
    ],
  );

  const mintNFTsProviderValue: MintNFTsContext = useMemo(
    () => ({
      handlePublicMint,
      handleAllowlistMint,
      mintCountData,
      mintPublicData,
      mintAllowlistData,
      estimateGasStatus,
    }),
    [
      handlePublicMint,
      handleAllowlistMint,
      mintCountData,
      mintPublicData,
      mintAllowlistData,
      estimateGasStatus,
    ],
  );

  return (
    <MintNFTsContext.Provider value={mintNFTsProviderValue}>
      {children}
    </MintNFTsContext.Provider>
  );
};

export default MintNFTsContext;
