export function getCurrentTimeInSeconds() {
  return new Date().getTime() / 1000;
}

// Note - this function provides an easy way to generate a new date for Public mint / Allowlist mint for a project.
// This function only needs to be run once to get the datestamp, which can then be stored in the enum in
// `/loser-club/src/enums/Config.ts`
function getUTCDateTimestamp() {
  const launchDate = new Date(
    Date.UTC(
      2022, // year
      2, // month (0 based)
      5, // day
      21, // hour
      0, // minute
      0, // second
    ),
  );
  const launchDateInSeconds = Math.floor(launchDate.getTime() / 1000);

  return launchDateInSeconds;
}

// note - add values for Public mint date / Allowlist mint date above => run this file with code runner
// => copy / paste the date output in the enum here: `/loser-club/src/enums/Config.ts`
const newUTCTimestamp = getUTCDateTimestamp();
console.log('newUTCTimestamp: ', newUTCTimestamp);
