import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  fonts: {
    // add custom fonts here
    // heading: 'Inter',
    // body: 'Inter',
  },
  colors: {
    // add other custom theme colors here
    loserClub: {
      50: '#6B46C1',
      100: '#322659',
      500: '#6B46C1',
    },
    vibe: {
      50: '#3182CE',
      100: '#234E52',
      500: 'rgb(126 132 255)',
    },
  },
});
