import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { BlockchainTransactionState } from '@enums';
import { MintEnabledStatus } from '@hooks';
import { EmotionStyles } from '@styles';
import { TransactionState } from '@usedapp/core';
import {
  IsMintPublicTooltipDisplayedDTO,
  getMintButtonText,
  getMintButtonTooltipMessage,
  MintButtonTooltipMessageDTO,
  getIsMintButtonTooltipDisplayed,
} from './utils';

type MintButtonProps = ButtonProps & {
  account?: string | null;
  customButtonStyles?: EmotionStyles;
  handlePublicMint: (
    mintCount: number,
    mintEnabledStatus: MintEnabledStatus,
  ) => void;
  mintEnabledStatus: MintEnabledStatus;
  isSoldOut: boolean;
  isConnectedToUnsupportedChain: boolean;
  isWalletLoading: boolean;
  mintPublicTxStatus: TransactionState;
  mintCount: number;
  numNftsStillAvailable: number | null;
  numNftsPossibleToMintInTx: number | null;
  numNftsWalletCanStillMint: number | null;
  numberMinted: number | null;
  isOwner?: boolean;
};

export const MintButton = ({
  account,
  customButtonStyles,
  handlePublicMint,
  mintEnabledStatus,
  isSoldOut,
  isConnectedToUnsupportedChain,
  isWalletLoading,
  mintPublicTxStatus,
  mintCount,
  numNftsStillAvailable,
  numNftsPossibleToMintInTx,
  numNftsWalletCanStillMint,
  numberMinted,
  isOwner,
  ...props
}: MintButtonProps) => {
  const mintButtonTooltipMessageDTO: MintButtonTooltipMessageDTO = {
    isSoldOut,
    mintCount,
    numNftsWalletCanStillMint,
    numberMinted,
    numNftsStillAvailable,
    numNftsPossibleToMintInTx,
    isConnectedToUnsupportedChain,
    mintEnabledStatus,
    account,
    isOwner,
  };
  const mintButtonTooltipMessage = getMintButtonTooltipMessage(
    mintButtonTooltipMessageDTO,
  );

  const mintButtonText = getMintButtonText(
    isSoldOut,
    mintEnabledStatus,
    account,
  );

  const zeroNftsPossibleToMintInTx =
    numNftsPossibleToMintInTx === null || numNftsPossibleToMintInTx <= 0;

  const maxPerWalletLimitReached =
    numNftsWalletCanStillMint === null || numNftsWalletCanStillMint <= 0;

  const isMintButtonDisabled =
    isWalletLoading ||
    isConnectedToUnsupportedChain ||
    mintEnabledStatus === MintEnabledStatus.NoMintingAllowed ||
    mintCount === 0 ||
    zeroNftsPossibleToMintInTx ||
    maxPerWalletLimitReached ||
    mintPublicTxStatus === BlockchainTransactionState.Mining ||
    !account;

  const issueConnecting = numNftsPossibleToMintInTx === null && !!account;

  const mintCountIsValid =
    numNftsPossibleToMintInTx !== null &&
    mintCount <= numNftsPossibleToMintInTx &&
    numNftsWalletCanStillMint !== null &&
    mintCount <= numNftsWalletCanStillMint;

  const isOwnerTooltipEnabled =
    isOwner &&
    numNftsWalletCanStillMint !== null &&
    numNftsWalletCanStillMint < 0;

  const isMintPublicTooltipDisplayedDTO: IsMintPublicTooltipDisplayedDTO = {
    issueConnecting,
    account,
    mintCountIsValid,
    numNftsWalletCanStillMint,
    isConnectedToUnsupportedChain,
    isOwnerTooltipEnabled,
  };

  const isTooltipDisplayed = getIsMintButtonTooltipDisplayed(
    isMintPublicTooltipDisplayedDTO,
  );

  const {
    loadingText,
    size,
    variant,
    fontSize,
    borderRadius,
    color,
    backgroundColor,
    letterSpacing,
    fontWeight,
  } = props;

  return (
    <Tooltip
      isDisabled={!isTooltipDisplayed}
      hasArrow
      label={mintButtonTooltipMessage}
      bg="gray.300"
      color="black"
      borderRadius="lg"
    >
      <div>
        <Button
          isLoading={mintPublicTxStatus === BlockchainTransactionState.Mining}
          disabled={isMintButtonDisabled}
          onClick={() => handlePublicMint(mintCount, mintEnabledStatus)}
          loadingText={loadingText || 'Minting...'}
          size={size || 'lg'}
          variant={variant || 'solid'}
          fontSize={fontSize || '16px'}
          borderRadius={borderRadius || 'xs'}
          color={color || '#6B46C1'}
          backgroundColor={backgroundColor || '#FFFFFF'}
          letterSpacing={letterSpacing || '0.24em'}
          fontWeight={fontWeight || '700'}
          css={customButtonStyles}
          {...props}
        >
          {mintButtonText}
        </Button>
      </div>
    </Tooltip>
  );
};
