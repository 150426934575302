import { Navigate, Route, Routes } from 'react-router-dom';
import { MintLandingPageScene } from 'src/scenes/MintLandingPage';

export const AppRouter = () => (
  <Routes>
    <Route path="/" element={<MintLandingPageScene />} />
    {/* add future routes here */}
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);
