import { forwardRef, RefObject } from 'react';
import { WhatIsDreamsBayshore, WhatIsSDImage } from '@assets';
import { Text, Button, Image } from '@chakra-ui/react';
import { Layout } from '@components';
import { css } from '@emotion/react';
import { Config } from '@enums';
import { MintEnabledStatus } from '@hooks';
import { Mint, MintAllowlist } from '@mint';
import { flexRow, flexColumn } from '@styles';
import { motion } from 'framer-motion';

interface WhatIsLCPageProps {
  isMobile: boolean;
  isWalletLoading?: boolean;
  handleScrollIntoView: (
    ref?: RefObject<HTMLHeadingElement> | undefined,
  ) => void;
  teamRef: RefObject<HTMLHeadingElement>;
  mintEnabledStatus: MintEnabledStatus;
}

const aboutSectionContainerStyles = (isMobile: boolean) =>
  css({
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'center',
    marginTop: 6,
  });
const flexCenterStyles = css({
  display: 'flex',
  justifyContent: 'center',
});
const buttonContainerStyles = (isMobile: boolean) =>
  css(isMobile ? flexColumn : flexRow, {
    justifyContent: 'flex-start',
    alignItems: isMobile ? 'center' : undefined,
    marginTop: 48,
  });
const headingStyles = css({
  scrollMargin: 80,
});
export const innerMobileLayoutStyles = css({
  padding: 8,
  width: '100%',
});
const whatIsLoserClubContainerStyles = (isMobile: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    width: isMobile ? '100%' : '40%',
    justifyContent: 'center',
    alignItems: isMobile ? 'center' : undefined,
    textAlign: isMobile ? 'center' : undefined,
  });

export const WhatIsLCPage = forwardRef<HTMLHeadingElement, WhatIsLCPageProps>(
  (
    {
      isMobile,
      isWalletLoading,
      handleScrollIntoView,
      teamRef,
      mintEnabledStatus,
    }: WhatIsLCPageProps,
    ref,
  ) => (
    <Layout
      bg="#FFFFFF"
      paddingX="8"
      width={isMobile ? '100%' : undefined}
      padding={isMobile ? '0 38px 50px 0' : '25px 0 15px 0'}
    >
      <div
        ref={ref}
        css={[
          aboutSectionContainerStyles(isMobile),
          headingStyles,
          isMobile ? innerMobileLayoutStyles : undefined,
        ]}
      >
        <div css={flexCenterStyles}>
          <Image
            alt={`What is ${Config.ProjectName}`}
            src={WhatIsSDImage}
            textAlign="center"
            width={isMobile ? '75%' : undefined}
          />
        </div>
        <div css={whatIsLoserClubContainerStyles(isMobile)}>
          <motion.div
            initial={isMobile ? false : 'hidden'}
            whileInView="visible"
            viewport={{ once: true, amount: 0.6 }}
            transition={{ duration: 0.6 }}
            variants={{
              hidden: { opacity: 0, x: 70 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <Image
              boxSize="450px"
              src={WhatIsDreamsBayshore}
              alt={`What is ${Config.ProjectName}`}
              marginTop={isMobile ? '-180px' : '-120px'}
              marginBottom="-120px"
            />
          </motion.div>
          <motion.div
            initial={isMobile ? false : 'hidden'}
            whileInView="visible"
            viewport={{ once: true, amount: 0.4 }}
            transition={{ duration: 0.6 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <Text
              color="black"
              marginTop="2"
              marginRight="3"
              paddingRight={isMobile ? '0' : '1%'}
              fontSize={18}
              fontWeight={300}
              lineHeight="36px"
              letterSpacing={'0.08em'}
            >
              <span css={{ fontWeight: 'bold' }}>Never Cool. Never Alone.</span>
              <br />
              'Utility', 'game-fi', all of these terms exist but often fail to
              deliver on the promises they make. But one thing remains true in
              this budding NFT space, and that is{' '}
              <span css={{ fontWeight: 'bold' }}>community</span>.
              <br />
              {Config.ProjectName} is the creation of 'Todd Nebula' (J'von). He
              clearly was never "cool" (the best of us aren't).
              <br />
              His goal - to sell art, put money back to the holders to do
              whatever they want, and create a hub for all losers to commune and
              create together.
              <br />
              We're bohemian hedonists. We celebrate non-conformity and
              spontaneous creativity.
              <br />
              For real raw artwork, the real utility is behind getting to know
              the artist and it is the people who determine value.
              <br />
              {Config.ProjectName} is deeply rooted in contrarianism. A
              reflection of the present day. Being a degen.
              <br />
              <span css={{ fontWeight: 'bold' }}>
                Let's be the new majority. It's the loser's time
              </span>
              .
            </Text>
          </motion.div>
          <div css={buttonContainerStyles(isMobile)}>
            <Button
              display="flex"
              size="md"
              marginBottom={isMobile ? '20px' : undefined}
              color="#FF63AD"
              variant="outline"
              onClick={() => handleScrollIntoView(teamRef)}
              border="3px solid #FF63AD"
              borderColor="#FF63AD"
              borderRadius="sm"
              letterSpacing={'0.24em'}
              fontWeight="700"
              _hover={{
                backgroundColor: '#FF63AD',
                color: '#FFFFFF',
              }}
            >
              MEET THE TEAM
            </Button>
            {mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled ? (
              <MintAllowlist
                isWalletLoading={isWalletLoading}
                display="flex"
                size="md"
                color="#EBB1FF"
                variant="outline"
                border="3px solid #EBB1FF"
                borderColor="#EBB1FF"
                marginLeft={isMobile ? 0 : '20px'}
                borderRadius="sm"
                _hover={{
                  backgroundColor: '#EBB1FF',
                  color: '#FFFFFF',
                }}
              />
            ) : (
              <Mint
                isWalletLoading={isWalletLoading}
                display="flex"
                size="md"
                color="#EBB1FF"
                variant="outline"
                border="3px solid #EBB1FF"
                borderColor="#EBB1FF"
                marginLeft="20px"
                borderRadius="sm"
                _hover={{
                  backgroundColor: '#EBB1FF',
                  color: '#FFFFFF',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  ),
);
