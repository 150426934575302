import { Button, ButtonProps, Stack, Text, Tooltip } from '@chakra-ui/react';
import { BlockchainTransactionState } from '@enums';
import { MintEnabledStatus } from '@hooks';
import { EmotionStyles } from '@styles';
import { TransactionState } from '@usedapp/core';
import {
  ALMintButtonTooltipMessageDTO,
  getIsAllowlistTooltipDisplayed,
  getMintAllowlistButtonText,
  getAllowlistMintButtonTooltipMessage,
  IsAllowlistMintButtonTooltipDisplayedDTO,
} from './utils';

type MintAllowlistButtonProps = ButtonProps & {
  account?: string | null;
  customButtonStyles?: EmotionStyles;
  handleAllowlistMint: (
    mintCount: number,
    mintEnabledStatus: MintEnabledStatus,
    account?: string | null,
  ) => void;
  mintEnabledStatus: MintEnabledStatus;
  isSoldOut: boolean;
  isConnectedToUnsupportedChain: boolean;
  isWalletLoading: boolean;
  isAllowlisted?: boolean | null;
  mintAllowlistTxStatus: TransactionState;
  mintCount: number;
  numNftsWalletCanStillMint: number | null;
  isOwner?: boolean;
  numberMinted: number | null;
};

export const MintAllowlistButton = ({
  account,
  customButtonStyles,
  handleAllowlistMint,
  mintEnabledStatus,
  isSoldOut,
  isConnectedToUnsupportedChain,
  isWalletLoading,
  isAllowlisted,
  mintAllowlistTxStatus,
  mintCount,
  numNftsWalletCanStillMint,
  isOwner,
  numberMinted,
  ...props
}: MintAllowlistButtonProps) => {
  const alButtonTooltipMsgDTO: ALMintButtonTooltipMessageDTO = {
    isSoldOut,
    mintCount,
    numNftsWalletCanStillMint,
    numberMinted,
    isConnectedToUnsupportedChain,
    mintEnabledStatus,
    isAllowlisted,
    account,
    isOwner,
  };
  const allowlistMintButtonTooltipMessage =
    getAllowlistMintButtonTooltipMessage(alButtonTooltipMsgDTO);

  const mintAllowlistButtonText = getMintAllowlistButtonText(
    isSoldOut,
    mintEnabledStatus,
    isAllowlisted,
    account,
  );

  const maxPerWalletLimitReached =
    numNftsWalletCanStillMint === null || numNftsWalletCanStillMint <= 0;

  const mintButtonDisabled =
    !isAllowlisted ||
    isWalletLoading ||
    isConnectedToUnsupportedChain ||
    mintEnabledStatus === MintEnabledStatus.NoMintingAllowed ||
    mintAllowlistTxStatus === BlockchainTransactionState.Mining ||
    mintCount === 0 ||
    maxPerWalletLimitReached ||
    !account;

  const mintCountIsValid =
    numNftsWalletCanStillMint !== null &&
    mintCount <= numNftsWalletCanStillMint;

  const allowlistUserMintedMax =
    Boolean(isAllowlisted) && numNftsWalletCanStillMint === 0;

  const isOwnerTooltipEnabled =
    isOwner &&
    numNftsWalletCanStillMint !== null &&
    numNftsWalletCanStillMint < 0;

  const isAlTooltipDisplayedDTO: IsAllowlistMintButtonTooltipDisplayedDTO = {
    numNftsWalletCanStillMint,
    account,
    isConnectedToUnsupportedChain,
    mintCountIsValid,
    isAllowlisted,
    allowlistUserMintedMax,
    isOwnerTooltipEnabled,
  };

  const isTooltipDisplayed = getIsAllowlistTooltipDisplayed(
    isAlTooltipDisplayedDTO,
  );

  const {
    loadingText,
    size,
    variant,
    fontSize,
    borderRadius,
    color,
    backgroundColor,
    letterSpacing,
    fontWeight,
  } = props;

  return (
    <Tooltip
      isDisabled={!isTooltipDisplayed}
      hasArrow
      label={allowlistMintButtonTooltipMessage}
      bg="gray.300"
      color="black"
      borderRadius="lg"
    >
      <Stack textAlign="center">
        {isAllowlisted && (
          <Text
            color="white"
            fontSize="md"
            fontWeight="medium"
            textAlign="center"
            position="relative"
          >
            Wallet is allowlisted!
          </Text>
        )}
        <Button
          isLoading={
            (isAllowlisted === null && Boolean(account)) ||
            mintAllowlistTxStatus === BlockchainTransactionState.Mining
          }
          disabled={mintButtonDisabled}
          onClick={() =>
            handleAllowlistMint(mintCount, mintEnabledStatus, account)
          }
          loadingText={
            isAllowlisted === null
              ? 'Verifying Allowlist'
              : loadingText || 'Minting...'
          }
          size={size || 'lg'}
          variant={variant || 'solid'}
          fontSize={fontSize || '16px'}
          borderRadius={borderRadius || 'xs'}
          color={color || '#553C9A'}
          backgroundColor={backgroundColor || '#FFFFFF'}
          letterSpacing={letterSpacing || '0.24em'}
          fontWeight={fontWeight || '700'}
          css={customButtonStyles}
          {...props}
        >
          {mintAllowlistButtonText}
        </Button>
      </Stack>
    </Tooltip>
  );
};
