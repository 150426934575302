import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEthers } from '@usedapp/core';
import { RootState } from '../store';
import AccountModalContext from './accountModalContext';
import { setIsWalletLoading } from './slice';

/**
 * Hook used to handle wallet loading when chain is changed or 
   enabling other scenes/routes to open the AccountModal without need for prop drilling.
 */

export function useWalletLoading() {
  const dispatch = useDispatch();
  const { account } = useEthers();

  const isWalletLoading = useSelector(
    ({ wallet }: RootState) => wallet.isWalletLoading,
  );

  const handleSetWalletLoading = useCallback(() => {
    dispatch(setIsWalletLoading(true));
    setTimeout(() => {
      dispatch(setIsWalletLoading(false));
    }, 2000);
  }, [dispatch]);

  useEffect(() => {
    window.ethereum?.on('chainChanged', handleSetWalletLoading);

    window.ethereum?.on('accountsChanged', handleSetWalletLoading);

    return () => {
      window.ethereum?.removeListener('chainChanged', handleSetWalletLoading);
      window.ethereum?.removeListener(
        'accountsChanged',
        handleSetWalletLoading,
      );
    };
  }, [handleSetWalletLoading]);

  useEffect(() => {
    handleSetWalletLoading();
  }, [account, handleSetWalletLoading]);

  return { handleSetWalletLoading, isWalletLoading };
}

/**
 * Hook used to expose global instance of the `onAccountModalOpen()` method,
 * enabling other scenes/routes to open the AccountModal without need for prop drilling.
 */

export const useAccountModal = () => useContext(AccountModalContext);
