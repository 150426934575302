import { Config } from '@enums';
import { MintEnabledStatus } from '@hooks';

export interface IncrementButtonTooltipMsgDTO {
  isSoldOut: boolean;
  mintCount: number;
  numNftsWalletCanStillMint: number | null;
  numberMinted: number | null;
  numNftsStillAvailable: number | null;
  numNftsPossibleToMintInTx: number | null;
  isOwner?: boolean;
}

export function getIncrementBtnTooltipMessage(
  incrementBtnTooltipMsgDTO: IncrementButtonTooltipMsgDTO,
) {
  const {
    mintCount,
    numNftsWalletCanStillMint,
    numberMinted,
    numNftsStillAvailable,
    numNftsPossibleToMintInTx,
    isSoldOut,
    isOwner,
  } = incrementBtnTooltipMsgDTO;

  if (isSoldOut) {
    return <p>Sorry, the {Config.ProjectName} collection is sold out!</p>;
  }
  if (numNftsWalletCanStillMint === Config.MaxNftsPerWallet_Public) {
    return (
      <p>
        The max number of NFTs that can be minted per wallet is{' '}
        {Config.MaxNftsPerWallet_Public}.
      </p>
    );
  }
  if (numNftsWalletCanStillMint === 0) {
    return (
      <p>
        You have already minted{' '}
        <span css={{ fontWeight: 'bold' }}>
          {Config.MaxNftsPerWallet_Public}
        </span>{' '}
        NFTs with this wallet!
        <br />
        This is the max number of NFTs that can be minted per wallet.
      </p>
    );
  }

  if (mintCount === numNftsWalletCanStillMint) {
    return (
      <p>
        You have already minted {numberMinted}{' '}
        {numberMinted === 1 ? 'NFT' : 'NFTs'}! You can only mint{' '}
        {numNftsWalletCanStillMint} more...{Config.MaxNftsPerWallet_Public} is
        the max per wallet limit.
      </p>
    );
  }
  if (mintCount === numNftsPossibleToMintInTx) {
    return (
      <p>
        The max number of NFTs that can be minted per transaction is{' '}
        {numNftsPossibleToMintInTx}.
      </p>
    );
  }
  if (
    numNftsPossibleToMintInTx !== null &&
    numNftsPossibleToMintInTx < Config.MaxNftsPerTx_Public
  ) {
    return (
      <p>
        There {numNftsStillAvailable === 1 ? 'is' : 'are'} only{' '}
        <span css={{ fontWeight: 'bold' }}>{numNftsStillAvailable}</span> more
        NFT{numNftsStillAvailable === 1 ? '' : 's'} left in the Loser Club
        collection - mint quickly!
      </p>
    );
  }
  if (
    isOwner &&
    numNftsWalletCanStillMint !== null &&
    numNftsWalletCanStillMint < 0
  ) {
    return (
      <p>
        Sorry owner - you can only mint a max of {Config.MaxNftsPerTx_Public}{' '}
        NFTs from the UI - if you would like to mint more, please mint directly
        from the <span css={{ fontWeight: 'bold' }}>mintOwner</span> function on
        the contract.
      </p>
    );
  }
}

export interface MintButtonTooltipMessageDTO {
  isSoldOut: boolean;
  mintCount: number;
  numNftsWalletCanStillMint: number | null;
  numberMinted: number | null;
  numNftsStillAvailable: number | null;
  numNftsPossibleToMintInTx: number | null;
  isConnectedToUnsupportedChain: boolean;
  mintEnabledStatus: MintEnabledStatus;
  account?: string | null;
  isOwner?: boolean;
}

export function getMintButtonTooltipMessage(
  mintButtonTooltipMessageDTO: MintButtonTooltipMessageDTO,
) {
  const {
    isSoldOut,
    mintCount,
    numNftsWalletCanStillMint,
    numberMinted,
    numNftsStillAvailable,
    numNftsPossibleToMintInTx,
    isConnectedToUnsupportedChain,
    mintEnabledStatus,
    account,
    isOwner,
  } = mintButtonTooltipMessageDTO;

  if (!account) {
    return (
      <p>Please connect your wallet to mint a {Config.ProjectName} NFT.</p>
    );
  }
  if (isSoldOut) {
    return <p>Sorry, the {Config.ProjectName} collection is sold out!</p>;
  }
  if (isConnectedToUnsupportedChain) {
    return <p>Please switch your wallet's network to Ethereum Mainnet.</p>;
  }
  if (mintEnabledStatus === MintEnabledStatus.NoMintingAllowed) {
    return <p>Minting has not started yet</p>;
  }
  if (Boolean(account) && !isConnectedToUnsupportedChain) {
    const incrementBtnTooltipMsgDTO: IncrementButtonTooltipMsgDTO = {
      mintCount,
      numNftsWalletCanStillMint,
      numberMinted,
      numNftsStillAvailable,
      numNftsPossibleToMintInTx,
      isSoldOut,
      isOwner,
    };
    return getIncrementBtnTooltipMessage(incrementBtnTooltipMsgDTO);
  }
  return <p>Please connect your wallet to mint a {Config.ProjectName} NFT.</p>;
}

export function getMintButtonText(
  isSoldOut: boolean,
  mintEnabledStatus: MintEnabledStatus,
  account?: string | null,
) {
  if (isSoldOut) {
    return 'SOLD OUT';
  }
  if (mintEnabledStatus === MintEnabledStatus.NoMintingAllowed && account) {
    return 'MINTING NOT STARTED';
  }
  return 'MINT NOW';
}

export interface IsMintPublicTooltipDisplayedDTO {
  issueConnecting: boolean;
  account?: string | null;
  mintCountIsValid: boolean;
  numNftsWalletCanStillMint: number | null;
  isConnectedToUnsupportedChain: boolean;
  isOwnerTooltipEnabled?: boolean;
}

export function getIsMintButtonTooltipDisplayed(
  isMintPublicTooltipDisplayedDTO: IsMintPublicTooltipDisplayedDTO,
) {
  const {
    issueConnecting,
    account,
    mintCountIsValid,
    numNftsWalletCanStillMint,
    isConnectedToUnsupportedChain,
    isOwnerTooltipEnabled,
  } = isMintPublicTooltipDisplayedDTO;

  return (
    !issueConnecting &&
    Boolean(account) &&
    (!mintCountIsValid ||
      (numNftsWalletCanStillMint !== null && numNftsWalletCanStillMint === 0) ||
      isConnectedToUnsupportedChain ||
      isOwnerTooltipEnabled)
  );
}
