import { LoserClubABI } from '@abis';
import { Config } from '@enums';
import { useContractCall, useEthers } from '@usedapp/core';
import { useGetCurrentContractAddress } from './useGetCurrentContractAddress';
import { MintEnabledStatus, useIsMintingEnabled } from './useIsMintingEnabled';

/**
 * Hook used to return key values related to number of mints for the current wallet account.
 * Returns `numberMinted` and `numNftsWalletCanStillMint`.
 */

export function useWalletMintsOfUser() {
  const { account } = useEthers();
  const currentContractAddress = useGetCurrentContractAddress();

  const mintEnabledStatus = useIsMintingEnabled();

  const [numMinted]: any =
    useContractCall({
      abi: LoserClubABI,
      address: currentContractAddress,
      method: 'numberMinted',
      args: [account],
    }) ?? [];

  const [ownerAddress]: any =
    useContractCall({
      abi: LoserClubABI,
      address: currentContractAddress,
      method: 'owner',
      args: [],
    }) ?? [];

  const isOwner = account === ownerAddress;

  const numberMinted = numMinted ? Number(numMinted.toString()) : null;

  const setNumNftsWalletCanStillMint = () => {
    if (mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled) {
      return numberMinted
        ? Config.MaxNftsPerWallet_AL - numberMinted
        : Config.MaxNftsPerWallet_AL;
    }
    if (mintEnabledStatus === MintEnabledStatus.PublicMintingEnabled) {
      return numberMinted
        ? Config.MaxNftsPerWallet_Public - numberMinted
        : Config.MaxNftsPerWallet_Public;
    }
    // if No Minting Allowed => set num wallet can mint to 0
    return 0;
  };

  const numNftsWalletCanStillMint = setNumNftsWalletCanStillMint();

  return {
    numberMinted,
    numNftsWalletCanStillMint,
    isOwner,
  };
}
