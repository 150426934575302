import { useContext } from 'react';
import MintNFTsContext from '../mintNftsContext';

/**
 * Hook used to expose global instance of the various state properties and functions needed to handle minting an NFT.
 * @returns `MintNFTsContext` object:
 * ```
 * {
 *   mintCountData: {
 *     mintCount: number;
 *     decrementNFTCount: () => void;
 *     incrementNFTCount: () => void;
 *     setMintCount: React.Dispatch<React.SetStateAction<number>>;
 *     ethRequiredToMint: string;
 *     setEthRequiredToMint: React.Dispatch<React.SetStateAction<string>>;
 *   };
 *   mintPublicData: {
 *     mintPublicState: TransactionStatus;
 *     mintPublic: (...args: any[]) => Promise<void>;
 *     mintPublicTxStatus: TransactionState;
 *     errorMessage?: string;
 *   };
 *   mintAllowlistData: {
 *     mintAllowlistState: TransactionStatus;
 *     mintAllowlist: (...args: any[]) => Promise<void>;
 *     mintAllowlistTxStatus: TransactionState;
 *     errorMessage?: string;
 *   };
 *   handlePublicMint: (
 *     mintCount: number,
 *     mintEnabledStatus: MintEnabledStatus,
 *   ) => void;;
 *   handleAllowlistMint: (
 *     mintCount: number,
 *     mintEnabledStatus: MintEnabledStatus,
 *     account?: string | null,
 *   ) => void;
 * }
 * ```
 */

export const useMint = () => useContext(MintNFTsContext);
