import React, { ReactElement, useMemo } from 'react';
import { useDisclosure } from '@chakra-ui/react';

interface AccountModalContext {
  isAccountModalOpen: boolean;
  handleAccountModalOpen: () => void;
  handleAccountModalClose: () => void;
}

interface AccountModalProviderProps {
  children: ReactElement;
}

const AccountModalContext = React.createContext<AccountModalContext>(
  {} as AccountModalContext,
);

AccountModalContext.displayName = 'AccountModalContext';

export const AccountModalProvider = ({
  children,
}: AccountModalProviderProps) => {
  const {
    isOpen: isAccountModalOpen,
    onOpen: handleAccountModalOpen,
    onClose: handleAccountModalClose,
  } = useDisclosure();

  const accountModalProviderValue: AccountModalContext = useMemo(
    () => ({
      isAccountModalOpen,
      handleAccountModalOpen,
      handleAccountModalClose,
    }),
    [isAccountModalOpen, handleAccountModalOpen, handleAccountModalClose],
  );

  return (
    <AccountModalContext.Provider value={accountModalProviderValue}>
      {children}
    </AccountModalContext.Provider>
  );
};

export default AccountModalContext;
