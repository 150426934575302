/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import initialState from './initialState';

export type TestReadAccountFetchPayload = PayloadAction<Array<BigNumber>>;
export type TestReadAccountSuccessPayload = PayloadAction<Array<BigNumber>>;
export type TestReadBalanceSuccessPayload = PayloadAction<string>;
export type TestTxTransferFetchPayload = PayloadAction<{
  amount: string;
  accountTo: string;
}>;
export type TestTxTransferSuccessPayload = PayloadAction<{
  amount: string;
  accountTo: string;
}>;
export type TestChangeMintAmountPayload = PayloadAction<number>;

export const reduxTestStateSlice = createSlice({
  name: 'reduxTestState',
  initialState,
  reducers: {
    testReadAccountFetch: (state, action: TestReadAccountFetchPayload) => {
      state.testIsAccountLoading = true;
    },

    testReadAccountSuccess: (state, action: TestReadAccountSuccessPayload) => {
      console.log('account change success');
      state.testDataAccounts = action.payload;
      state.testIsAccountLoading = false;
    },

    testReadAccountFailure: (state) => {
      console.log('account was unable to connect');
      state.testDataAccounts = [];
      state.testIsAccountLoading = false;
    },

    testReadAccountEmpty: (state) => {
      state.testDataAccounts = [];
      state.testIsAccountLoading = false;
    },

    testReadBalanceFetch: () => {},

    testReadBalanceSuccess: (state, action: TestReadBalanceSuccessPayload) => {
      console.log('Balance: ', action.payload);
      state.testBalance = action.payload;
    },

    testReadBalanceFailure: () => {},

    testTxTransferFetch: (state, action: TestTxTransferFetchPayload) => {},

    testTxTransferSuccess: (
      state,
      action: PayloadAction<{ amount: string; accountTo: string }>,
    ) => {
      console.log(
        `${action.payload.amount} Coins successfully sent to ${action.payload.accountTo}`,
      );
    },

    testTxTransferFailure: () => {},

    testTxMintFetch: () => {},

    testTxMintSuccess: () => {
      console.log('mint was successful');
    },

    testTxMintFailure: () => {},

    testChangeMintAmount: (state, action: TestChangeMintAmountPayload) => {
      state.testMintAmount = action.payload < 0 ? 0 : action.payload;
    },
  },
});

export const {
  testReadBalanceFetch,
  testReadBalanceSuccess,
  testReadBalanceFailure,
  testTxTransferFetch,
  testTxTransferSuccess,
  testTxTransferFailure,
  testReadAccountFetch,
  testReadAccountSuccess,
  testReadAccountFailure,
  testReadAccountEmpty,
  testTxMintFetch,
  testTxMintSuccess,
  testTxMintFailure,
  testChangeMintAmount,
} = reduxTestStateSlice.actions;

export default reduxTestStateSlice.reducer;
