import { useMediaQuery } from 'react-responsive';

export function useIsMobile() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return isTabletOrMobile;
}

export function useIsSmallMobile() {
  const isSmallMobile = useMediaQuery({ query: '(max-width: 600px)' });
  return isSmallMobile;
}

export function useIsExtraSmallMobile() {
  const isSmallMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isExtraSmallMobile = useMediaQuery({ query: '(max-height: 680px)' });
  return isSmallMobile && isExtraSmallMobile;
}

export function useIsShortScreenDevice() {
  const isShortScreenDevice = useMediaQuery({ query: '(max-height: 690px)' });
  return isShortScreenDevice;
}

export function useIsNotFullScreenDevice() {
  const isShortScreenDevice = useMediaQuery({ query: '(max-height: 900px)' });
  return isShortScreenDevice;
}

export function useResponsive() {
  const isMobile = useIsMobile();
  const isSmallMobile = useIsSmallMobile();
  const isShortScreenDevice = useIsShortScreenDevice();
  const isNotFullScreenDevice = useIsNotFullScreenDevice();
  const isExtraSmallMobile = useIsExtraSmallMobile();

  return {
    isMobile,
    isSmallMobile,
    isShortScreenDevice,
    isNotFullScreenDevice,
    isExtraSmallMobile,
  };
}
