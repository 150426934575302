import { css } from '@emotion/react';

export const lcLogoImageStyles = (
  isMobile: boolean,
  isSmallMobile: boolean,
  isExtraSmallMobile: boolean,
  isShortScreenDevice: boolean,
  isNotFullScreenDevice: boolean,
) =>
  css({
    display: 'inline-block',
    overflow: 'hidden',
    transform: setLogoImageTransform(
      isMobile,
      isSmallMobile,
      isShortScreenDevice,
      isNotFullScreenDevice,
    ),
    marginTop: setLogoImageMarginTop(
      isMobile,
      isSmallMobile,
      isExtraSmallMobile,
    ),
    position: isMobile ? undefined : 'absolute',
    maxWidth: isMobile ? 600 : 1100,
    top: isMobile ? undefined : 50,
  });

function setLogoImageMarginTop(
  isMobile: boolean,
  isSmallMobile: boolean,
  isExtraSmallMobile: boolean,
) {
  if (isExtraSmallMobile) {
    return -160;
  }
  if (isSmallMobile) {
    return -40;
  }
  if (isMobile) {
    return -170;
  }
  return undefined;
}

function setLogoImageTransform(
  isMobile: boolean,
  isSmallMobile: boolean,
  isShortScreenDevice: boolean,
  isNotFullScreenDevice: boolean,
) {
  if (isShortScreenDevice || isNotFullScreenDevice) {
    return 'scale(1)';
  }
  if (isSmallMobile) {
    return 'scale(1.5)';
  }
  if (isMobile) {
    return 'scale(2.3)';
  }
  return 'scale(1.3)';
}

export function setLayoutPadding(
  isMobile: boolean,
  isSmallMobile: boolean,
  paddingLocation: PaddingLocation,
) {
  if (paddingLocation === PaddingLocation.Top) {
    if (isSmallMobile) {
      return '80px !important';
    }
    if (isMobile) {
      return '200px !important';
    }
    return '120px !important';
  } else {
    if (isSmallMobile) {
      return '80px !important';
    }
    if (isMobile) {
      return '160px !important';
    }
    return '80px !important';
  }
}

export enum PaddingLocation {
  Top = 'Top',
  Bottom = 'Bottom',
}
