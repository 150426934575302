import { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { BlockchainTransactionState, Config, EstimateGasStatus } from '@enums';
import { ToastStatus } from '@models';
import { TransactionState, TransactionStatus } from '@usedapp/core';
import { v4 } from 'uuid';
import { MintEnabledStatus } from './useIsMintingEnabled';

interface BlockchainTransactionStatus {
  toastStatus: ToastStatus | null;
  toastTitle: string | EmotionJSX.Element | null;
  toastDescription: string | EmotionJSX.Element | null;
  id?: string;
}

interface UserWalletData {
  numberMinted: number | null;
  numNftsWalletCanStillMint: number | null;
  numNftsPossibleToMintInTx: number | null;
}

export function useBlockchainToast(
  mintState: TransactionStatus,
  mintEnabledStatus: MintEnabledStatus,
  userWalletData: UserWalletData | null,
  estimateGasStatus: EstimateGasStatus,
  customSuccessDescription: string,
) {
  const toast = useToast();
  const { status, errorMessage } = mintState;
  const [txStatus, setTxStatus] =
    useState<BlockchainTransactionStatus | null>();

  useEffect(() => {
    const toastId = v4();
    const currentTxStatus = generateToastContent(
      mintEnabledStatus,
      status,
      customSuccessDescription,
      toastId,
      userWalletData,
      estimateGasStatus,
      errorMessage,
    );
    setTxStatus(currentTxStatus);
    if (!currentTxStatus.toastStatus) {
      return;
    }
    if (toastId && !toast.isActive(toastId)) {
      toast({
        id: toastId,
        title: currentTxStatus.toastTitle,
        description: currentTxStatus.toastDescription,
        status: currentTxStatus.toastStatus,
        duration: 10000,
        isClosable: true,
      });
    }

    if (
      status === BlockchainTransactionState.Success ||
      status === BlockchainTransactionState.Fail ||
      status === BlockchainTransactionState.Exception ||
      estimateGasStatus !== EstimateGasStatus.NoError
    ) {
      removeToastAriaLiveNode();
    }
  }, [status, errorMessage, mintState, estimateGasStatus]);

  return txStatus;
}

function generateToastContent(
  mintEnabledStatus: MintEnabledStatus,
  status: TransactionState,
  customSuccessDescription: string,
  toastId: string,
  userWalletData: UserWalletData | null,
  estimateGasStatus: EstimateGasStatus,
  errorMessage?: string,
) {
  const txStatus: BlockchainTransactionStatus = {
    toastStatus: null,
    toastTitle: null,
    toastDescription: null,
    id: toastId,
  };

  if (status === BlockchainTransactionState.Fail) {
    txStatus.toastStatus = 'error';
    txStatus.toastTitle = 'We had trouble loading this information.';
    txStatus.toastDescription = 'Please try again later.';
  }
  if (estimateGasStatus === EstimateGasStatus.CannotEstimateGas) {
    txStatus.toastStatus = 'error';
    txStatus.toastTitle = 'We had trouble processing your request.';
    txStatus.toastDescription = 'Please try again later.';
  }
  if (status === BlockchainTransactionState.Success) {
    txStatus.toastStatus = 'success';
    txStatus.toastTitle = `${Config.ProjectName} minted.`;
    txStatus.toastDescription = customSuccessDescription;
  }
  if (status === BlockchainTransactionState.Exception) {
    if (
      errorMessage?.includes('minting not enabled') ||
      errorMessage?.includes('not public sale time yet') ||
      errorMessage?.includes('not allowlist sale time yet')
    ) {
      txStatus.toastStatus = 'info';
      txStatus.toastTitle = 'Minting not yet enabled.';
      txStatus.toastDescription = (
        <span>
          Keep an eye on our{' '}
          <a
            aria-label="Check us out on twitter"
            href="https://twitter.com/loserclubreborn"
            target="_blank"
            css={{ textDecoration: 'underline' }}
          >
            Twitter
          </a>{' '}
          for more info.
        </span>
      );
    }

    if (
      errorMessage?.includes('insufficient funds') ||
      estimateGasStatus === EstimateGasStatus.InsufficientFunds
    ) {
      txStatus.toastStatus = 'error';
      txStatus.toastTitle = 'Insufficient funds.';
      txStatus.toastDescription =
        'You do not have enough ETH to cover mint + gas.';
    }

    if (
      mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled &&
      errorMessage?.includes('exceeds max wallet')
    ) {
      txStatus.toastStatus = 'warning';
      if (userWalletData) {
        const { numberMinted, numNftsWalletCanStillMint } = userWalletData;
        if (numberMinted && numberMinted >= Config.MaxNftsPerWallet_AL) {
          txStatus.toastTitle = `Sorry, you have already minted ${Config.MaxNftsPerWallet_AL} NFTs.`;
          txStatus.toastDescription =
            'This is the maximum number of NFTs that can be minted per wallet during allowlist minting.';
        } else if (numNftsWalletCanStillMint) {
          txStatus.toastTitle = `You have already minted ${numberMinted} NFTs.`;
          txStatus.toastDescription = `The maximum number of NFTs that can be minted per wallet during allowlist minting is ${Config.MaxNftsPerWallet_AL}. You can only mint ${numNftsWalletCanStillMint} more NFTs with this wallet.`;
        }
      } else {
        txStatus.toastTitle = `Sorry, you have already minted ${Config.MaxNftsPerWallet_AL} NFTs.`;
        txStatus.toastDescription =
          'This is the maximum number of NFTs that can be minted per wallet during allowlist minting.';
      }
    }

    if (
      mintEnabledStatus === MintEnabledStatus.PublicMintingEnabled &&
      errorMessage?.includes('exceeds max wallet')
    ) {
      txStatus.toastStatus = 'warning';
      if (userWalletData) {
        const { numberMinted, numNftsWalletCanStillMint } = userWalletData;
        if (numberMinted && numberMinted >= Config.MaxNftsPerWallet_Public) {
          txStatus.toastTitle = `Sorry, you have already minted ${Config.MaxNftsPerWallet_Public} NFTs.`;
          txStatus.toastDescription =
            'This is the maximum number of NFTs that can be minted per wallet.';
        } else if (numNftsWalletCanStillMint) {
          txStatus.toastTitle = `You have already minted ${numberMinted} NFTs.`;
          txStatus.toastDescription = `The maximum number of NFTs that can be minted per wallet is ${Config.MaxNftsPerTx_Public}. You can only mint ${numberMinted} more NFTs with this wallet.`;
        }
      } else {
        txStatus.toastTitle = `Sorry, you have already minted ${Config.MaxNftsPerWallet_Public} NFTs.`;
        txStatus.toastDescription =
          'This is the maximum number of NFTs that can be minted per wallet.';
      }
    }
  }

  return txStatus;
}

function removeToastAriaLiveNode() {
  setTimeout(() => {
    const divs = document.querySelectorAll('body > div');
    divs.forEach((div) => {
      if (div.hasAttribute('data-reach-live-polite')) {
        div.remove();
      }
    });
  }, 200);
}
