import { useEffect, useState } from 'react';

export const useIsScrolledToTopOfPage = () => {
  const [isScrolledToTopOfPage, setIsScrolledToTopOfPage] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

    function handleScroll() {
      if (window.scrollY > 20) {
        setIsScrolledToTopOfPage(false);
      } else {
        setIsScrolledToTopOfPage(true);
      }
    }
  }, []);

  return isScrolledToTopOfPage;
};
