import { Icon, IconButtonProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

interface IconCustomProps {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  color?: string;
  filter?: string;
  noHoverStyles?: boolean;
}

const linkHoverStyles = (noHoverStyles?: boolean) =>
  css({
    padding: 0,
    margin: 0,
    display: 'flex',
    color: 'rgb(255, 255, 255)',
    '&:hover': noHoverStyles
      ? undefined
      : {
          transition: 'opacity 0.1s ease-in-out',
          opacity: '0.7',
        },
  });

export function IconCustom({
  src,
  alt,
  width,
  height,
  color,
  filter,
  noHoverStyles = false,
}: IconCustomProps) {
  return (
    <div css={linkHoverStyles(noHoverStyles)}>
      <Icon
        color={color}
        _hover={
          noHoverStyles
            ? undefined
            : {
                color: 'whiteAlpha.700',
              }
        }
        as={(props: IconButtonProps) =>
          (
            // @ts-ignore
            <img {...props} src={src} alt={alt} />
          ) as EmotionJSX.Element
        }
        width={width}
        height={height}
        filter={filter}
      />
    </div>
  );
}
