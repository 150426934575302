import { LoserClubABI } from '@abis';
import { Contract } from '@ethersproject/contracts';
import { useGetCurrentContractAddress } from '@hooks';
import { useContractFunction } from '@usedapp/core';

export function useMintContractCall() {
  const currentContractAddress = useGetCurrentContractAddress();
  const contractInstance = new Contract(currentContractAddress, LoserClubABI);

  const { state: mintPublicState, send: mintPublic } = useContractFunction(
    contractInstance,
    'mintPublic',
    {},
  );
  const mintPublicTxStatus = mintPublicState.status;
  const errorMessage = mintPublicState.errorMessage;

  return {
    mintPublicState,
    mintPublic,
    mintPublicTxStatus,
    errorMessage,
  };
}
