import { IconButton, IconButtonProps, Link, LinkProps } from '@chakra-ui/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Size } from '@models';

type SocialIconButtonProps = IconButtonProps &
  LinkProps & {
    icon: EmotionJSX.Element;
    iconSize?: Size;
    noHoverStyles?: boolean;
  };

export function SocialIconButton({
  icon,
  noHoverStyles = false,
  iconSize = 'lg',
  fontSize = '38',
  ...props
}: SocialIconButtonProps) {
  return (
    <IconButton
      as={Link}
      variant="ghost"
      size={iconSize}
      fontSize={fontSize}
      colorScheme="white"
      color="white"
      icon={icon}
      marginRight="6"
      _hover={
        noHoverStyles
          ? undefined
          : {
              color: 'whiteAlpha.700',
            }
      }
      _active={{
        transform: 'scale(0.98)',
      }}
      {...props}
    />
  );
}
