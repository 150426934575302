import { css } from '@emotion/react';

export const flexRow = css({
  display: 'flex',
  flexDirection: 'row',
});
export const flexColumn = css({
  display: 'flex',
  flexDirection: 'column',
});
export const mobileLayoutStyles = css({
  width: '100%',
  padding: 38,
});
export const layoutStyles = css({
  paddingTop: 90,
});
