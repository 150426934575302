import { useCallback, useEffect, useState } from 'react';
import { Config } from '@enums';
import { useEthers } from '@usedapp/core';

export function useMintCount(minCount: number, maxCount: number) {
  const [mintCount, setMintCount] = useState(1);
  const [ethRequiredToMint, setEthRequiredToMint] = useState(
    Config.MintPrice.toFixed(3),
  );

  const { account } = useEthers();

  useEffect(() => {
    // reset ethRequiredToMint to 0.088 when wallet account is switched
    setEthRequiredToMint(Config.MintPrice.toFixed(3));
  }, [account]);

  const decrementNFTCount = useCallback(() => {
    if (mintCount <= minCount) {
      return;
    }

    setMintCount((c) => {
      const decrementedMintCount = c - 1;
      const ethToSendWithTransaction = (Config.MintPrice * decrementedMintCount)
        .toFixed(3)
        .toString();

      setEthRequiredToMint(ethToSendWithTransaction);

      return decrementedMintCount;
    });
  }, [minCount, mintCount]);

  const incrementNFTCount = useCallback(() => {
    if (mintCount >= maxCount) {
      return;
    }

    setMintCount((c) => {
      const incrementedMintCount = c + 1;
      const ethToSendWithTransaction = (Config.MintPrice * incrementedMintCount)
        .toFixed(3)
        .toString();

      setEthRequiredToMint(ethToSendWithTransaction);

      return incrementedMintCount;
    });
  }, [maxCount, mintCount]);

  return {
    mintCount,
    decrementNFTCount,
    incrementNFTCount,
    setMintCount,
    ethRequiredToMint,
    setEthRequiredToMint,
  };
}
