import { useDispatch } from 'react-redux';
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import {
  setIsConnectedToUnsupportedChain,
  setIsConnectedToUnsupportedChainOnLogin,
} from '@state/wallet';
import { useEthers } from '@usedapp/core';

interface WrongNetworkModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function WrongNetworkModal({ isOpen, onClose }: WrongNetworkModalProps) {
  const dispatch = useDispatch();
  const { library } = useEthers();

  async function handleSwitchToEthereumMainNet() {
    try {
      await library?.provider.request?.({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }],
      });
      dispatch(setIsConnectedToUnsupportedChain(false));
      dispatch(setIsConnectedToUnsupportedChainOnLogin(false));
    } catch (error) {
      console.log('Error switching to Ethereum mainnet: ', error);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent
        background="gray.900"
        border="1px"
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="3xl"
      >
        <ModalHeader
          color="white"
          background="gray.700"
          borderRadius="3xl"
          borderBottomRadius="0"
          marginBottom="4"
          px={4}
          fontSize="lg"
          fontWeight="medium"
        >
          Wrong Network
        </ModalHeader>
        <ModalCloseButton
          color="white"
          fontSize="sm"
          _hover={{
            color: 'whiteAlpha.700',
          }}
        />
        <ModalBody pt={0} px={4}>
          <Flex justifyContent="space-between" alignItems="center" mb={3}>
            <Text color="gray.400" fontSize="md">
              Please connect to the Ethereum network.
            </Text>
          </Flex>
          <Flex justifyContent="center" alignItems="center" mb={4} mt={4}>
            <Button
              onClick={handleSwitchToEthereumMainNet}
              variant="outline"
              size="lg"
              borderColor="rgb(126 132 255)"
              borderRadius="3xl"
              color="blue.500"
              fontSize="20px"
              fontWeight="normal"
              px={2}
              height="26px"
              padding="40px 28px"
              boxShadow={'rgb(126 132 255) 0px 3px 0px 1px'}
              _hover={{
                backgroundColor: 'black',
                borderColor: 'blue.300',
              }}
            >
              Switch to Ethereum Mainnet
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
