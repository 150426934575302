import { LoserClubABI } from '@abis';
import { Config } from '@enums';
import { useContractCall } from '@usedapp/core';
import { useGetCurrentContractAddress } from './useGetCurrentContractAddress';

/**
 * Hook used to retrieve various data about the collection's token supply.
 */
export function useLoserClubTokenSupply() {
  const currentContractAddress = useGetCurrentContractAddress();

  const [totalSupply]: any =
    useContractCall({
      abi: LoserClubABI,
      address: currentContractAddress,
      method: 'totalSupply',
      args: [],
    }) ?? [];

  const [maxSupply]: any =
    useContractCall({
      abi: LoserClubABI,
      address: currentContractAddress,
      method: 'maxSupply',
      args: [],
    }) ?? [];

  const totalSupplyStringified = totalSupply ? totalSupply.toString() : null;
  const maxSupplyStringified = maxSupply ? maxSupply.toString() : null;

  const numNftsStillAvailable =
    totalSupply !== undefined && maxSupply !== undefined
      ? Number(maxSupplyStringified) - Number(totalSupplyStringified)
      : null;

  const isSoldOut =
    numNftsStillAvailable === 0 && numNftsStillAvailable !== null;

  // note - this property is to ensure user cannot mint more than maxSupply
  const numNftsPossibleToMintInTx =
    numNftsStillAvailable !== null &&
    numNftsStillAvailable >= Config.MaxNftsPerTx_Public
      ? Config.MaxNftsPerTx_Public
      : numNftsStillAvailable;

  return {
    totalSupplyStringified,
    maxSupplyStringified,
    numNftsStillAvailable,
    isSoldOut,
    numNftsPossibleToMintInTx,
  };
}
