import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState from './initialState';

export type SetIsConnectedToUnsupportedChainPayload = PayloadAction<boolean>;

export type SetIsConnectedToUnsupportedChainOnLoginPayload =
  PayloadAction<boolean>;

export type SetIsWalletLoadingPayload = PayloadAction<boolean>;

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setIsConnectedToUnsupportedChain: (
      state,
      action: SetIsConnectedToUnsupportedChainPayload,
    ) => {
      state.isConnectedToUnsupportedChain = action.payload;
    },
    setIsConnectedToUnsupportedChainOnLogin: (
      state,
      action: SetIsConnectedToUnsupportedChainPayload,
    ) => {
      state.isConnectedToUnsupportedChainOnLogin = action.payload;
    },
    setIsWalletLoading: (state, action: SetIsWalletLoadingPayload) => {
      state.isWalletLoading = action.payload;
    },
  },
});

export const {
  setIsConnectedToUnsupportedChain,
  setIsConnectedToUnsupportedChainOnLogin,
  setIsWalletLoading,
} = walletSlice.actions;

export default walletSlice.reducer;
