import { useEffect } from 'react';
import { LCHeroImage, LCLogo } from '@assets';
import { Text, Image } from '@chakra-ui/react';
import { BasePageLayout, Layout, ProgressBarMintButton } from '@components';
import { css } from '@emotion/react';
import { Config, BlockchainTransactionState, EstimateGasStatus } from '@enums';
import {
  MintEnabledStatus,
  useIsMintingEnabled,
  useIsScrolledToTopOfPage,
  useLoserClubTokenSupply,
  useWalletMintsOfUser,
  useResponsive,
} from '@hooks';
import { Mint, MintAllowlist, useMint } from '@mint';
import { useWalletLoading } from '@state';
import { layoutStyles } from '@styles';
import { useEthers } from '@usedapp/core';
import { TopNav } from './components';
import { PaddingLocation, setLayoutPadding, lcLogoImageStyles } from './utils';

const purpleXStyles = css({
  margin: '0 8px',
  fontSize: 14,
  fontWeight: 800,
  color: '#44337A',
});
const bgImageFadeInStyles = (isSmallMobile?: boolean) =>
  css({
    animation: 'fade 1.5s ease-in',
    '@keyframes fade': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: isSmallMobile
        ? 'linear-gradient(to bottom right,rgba(7,7,99,0.25),rgba(7,7,99,0.25))'
        : 'linear-gradient(to bottom right,rgba(7,7,99,0.2),rgba(7,7,99,0.2))',
      opacity: isSmallMobile ? 1.0 : 0.5,
    },
  });
const mobileLayoutStyles = css({
  width: '100%',
});

function MintLandingPageScene() {
  const {
    isMobile,
    isSmallMobile,
    isShortScreenDevice,
    isNotFullScreenDevice,
    isExtraSmallMobile,
  } = useResponsive();
  const isScrolledToTopOfPage = useIsScrolledToTopOfPage();

  const { isWalletLoading } = useWalletLoading();
  const { account } = useEthers();
  const {
    maxSupplyStringified,
    numNftsStillAvailable,
    isSoldOut,
    numNftsPossibleToMintInTx,
  } = useLoserClubTokenSupply();
  const { mintCountData, mintPublicData, estimateGasStatus } = useMint();
  const { mintPublicTxStatus } = mintPublicData;
  const mintEnabledStatus = useIsMintingEnabled();
  const { numNftsWalletCanStillMint } = useWalletMintsOfUser();

  const { setMintCount, setEthRequiredToMint } = mintCountData;

  const getHasUserMintedMaxAmount = () => {
    if (mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled) {
      return numNftsWalletCanStillMint <= 0;
    }
    if (mintEnabledStatus === MintEnabledStatus.PublicMintingEnabled) {
      return (
        numNftsPossibleToMintInTx === null ||
        numNftsPossibleToMintInTx <= 0 ||
        numNftsWalletCanStillMint <= 0
      );
    }
    return false;
  };
  const userMintedMaxNFTs = getHasUserMintedMaxAmount();

  useEffect(() => {
    if (
      mintPublicTxStatus !== BlockchainTransactionState.Mining &&
      estimateGasStatus === EstimateGasStatus.NoError
    ) {
      if (userMintedMaxNFTs) {
        setMintCount(0);
        // reset ethRequiredToMint to 0.00 when wallet account is switched
        setEthRequiredToMint((0.0).toFixed(3));
      } else {
        setMintCount(1);
      }
    }
  }, [
    numNftsWalletCanStillMint,
    numNftsPossibleToMintInTx,
    userMintedMaxNFTs,
    setMintCount,
    account,
    setEthRequiredToMint,
    mintPublicTxStatus,
  ]);

  const setNftsStillAvailableCountText = () => {
    if (isSoldOut) {
      return 'SOLD OUT';
    }
    if (numNftsStillAvailable) {
      return (
        <span>
          {numNftsStillAvailable}
          <span css={{ fontSize: 28, fontWeight: 300 }}>/</span>
          {maxSupplyStringified ?? Config.MaxSupply} AVAILABLE
        </span>
      );
    }
    return (
      <span css={{ paddingTop: 8 }}>
        MAX SUPPLY <span css={{ fontSize: 28, fontWeight: 300 }}></span>
        {maxSupplyStringified ?? Config.MaxSupply}
      </span>
    );
  };

  const nftsStillAvailableCountText = setNftsStillAvailableCountText();

  return (
    <BasePageLayout>
      <TopNav
        isScrolledToTopOfPage={isScrolledToTopOfPage}
        isMobile={isMobile}
        isSmallMobile={isSmallMobile}
        isShortScreenDevice={isShortScreenDevice}
        mintEnabledStatus={mintEnabledStatus}
      />
      <Layout
        customContainerStyles={[
          isMobile ? mobileLayoutStyles : layoutStyles,
          bgImageFadeInStyles(isSmallMobile),
        ]}
        bgImage={isSmallMobile ? undefined : `url('${LCHeroImage}')`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        paddingTop={setLayoutPadding(
          isMobile,
          isSmallMobile,
          PaddingLocation.Top,
        )}
        paddingBottom={setLayoutPadding(
          isMobile,
          isSmallMobile,
          PaddingLocation.Bottom,
        )}
        minHeight="100vh"
        maxHeight="100vh"
        minWidth="100%"
        maxWidth="100%"
        paddingX={isMobile ? undefined : 72}
      >
        {!isMobile && (
          <Text
            color="rgba(27, 27, 27, 1)"
            fontSize={18}
            fontWeight="400"
            letterSpacing={'0.3em'}
          >
            THE FOUR HUNDRED <span css={purpleXStyles}>X</span> J'VON
          </Text>
        )}
        {!isMobile && (
          <Text
            color="rgba(27, 27, 27, 1)"
            fontSize={12}
            fontWeight="700"
            letterSpacing={'0.24em'}
            marginTop="6"
          >
            PRESENTS
          </Text>
        )}
        <Image
          src={LCLogo}
          alt="Loser Club Logo"
          css={lcLogoImageStyles(
            isMobile,
            isSmallMobile,
            isExtraSmallMobile,
            isShortScreenDevice,
            isNotFullScreenDevice,
          )}
        />
      </Layout>
      {!isSmallMobile ? (
        <Layout
          paddingTop="0px"
          paddingBottom="0px"
          marginTop={isMobile ? '-360px' : '-230px'}
          background="transparent"
        >
          {mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled ? (
            <MintAllowlist
              isIncrementDecrementCountDisplayed
              isWalletLoading={isWalletLoading}
              marginTop="2"
              padding="14px 22px"
              _active={{
                backgroundColor: 'gray.200',
                boxShadow: '#6B46C1 0px 3px 0px 1px',
              }}
              _focus={{
                borderColor: '#6B46C1',
              }}
              _hover={{
                backgroundColor: 'gray.100',
                borderColor: '#6B46C1',
              }}
            />
          ) : (
            <Mint
              isIncrementDecrementCountDisplayed
              isWalletLoading={isWalletLoading}
              marginTop="2"
              padding="14px 22px"
              _active={{
                backgroundColor: 'gray.200',
                boxShadow: '#6B46C1 0px 3px 0px 1px',
              }}
              _focus={{
                borderColor: '#6B46C1',
              }}
              _hover={{
                backgroundColor: 'gray.100',
                borderColor: '#6B46C1',
              }}
            />
          )}
          {!isWalletLoading ? (
            <Text
              textAlign="center"
              fontSize={isMobile ? '16px' : '18px'}
              color="white"
              backgroundColor={
                isMobile
                  ? 'rgba(107, 70, 193, 0.80)'
                  : 'rgba(107, 70, 193, 0.70)'
              }
              fontWeight="400"
              letterSpacing={isSoldOut ? '0.14em' : '0.2em'}
              marginTop="2px"
              marginBottom="10px"
              padding={isSoldOut ? '12px 24px 12px' : '6px 24px 12px'}
              borderRadius="2xl"
              position="relative"
              width={isMobile ? '300px' : undefined}
            >
              {nftsStillAvailableCountText}
            </Text>
          ) : null}
          {isWalletLoading ? <ProgressBarMintButton /> : null}
        </Layout>
      ) : (
        <div
          css={{
            paddingTop: '0px',
            paddingBottom: '0px',
            marginTop: '-360px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '100vw',
          }}
        >
          {mintEnabledStatus === MintEnabledStatus.AllowlistMintingEnabled ? (
            <MintAllowlist
              isIncrementDecrementCountDisplayed
              isWalletLoading={isWalletLoading}
              marginTop="2"
              padding="14px 22px"
              _active={{
                backgroundColor: 'gray.200',
                boxShadow: '#6B46C1 0px 3px 0px 1px',
              }}
              _focus={{
                borderColor: '#6B46C1',
              }}
              _hover={{
                backgroundColor: 'gray.100',
                borderColor: '#6B46C1',
              }}
            />
          ) : (
            <Mint
              isIncrementDecrementCountDisplayed
              isWalletLoading={isWalletLoading}
              marginTop="2"
              padding="14px 22px"
              _active={{
                backgroundColor: 'gray.200',
                boxShadow: '#6B46C1 0px 3px 0px 1px',
              }}
              _focus={{
                borderColor: '#6B46C1',
              }}
              _hover={{
                backgroundColor: 'gray.100',
                borderColor: '#6B46C1',
              }}
            />
          )}
          {!isWalletLoading ? (
            <Text
              textAlign="center"
              fontSize={isMobile ? '16px' : '18px'}
              color="white"
              backgroundColor={
                isMobile
                  ? 'rgba(107, 70, 193, 0.80)'
                  : 'rgba(107, 70, 193, 0.70)'
              }
              fontWeight="400"
              letterSpacing={isSoldOut ? '0.14em' : '0.2em'}
              marginTop="10px"
              marginBottom="10px"
              padding={isSoldOut ? '12px 24px 12px' : '6px 24px 12px'}
              borderRadius="2xl"
              position="relative"
              width={'300px'}
            >
              {nftsStillAvailableCountText}
            </Text>
          ) : null}
          {isWalletLoading ? <ProgressBarMintButton /> : null}
        </div>
      )}
    </BasePageLayout>
  );
}

export default MintLandingPageScene;
