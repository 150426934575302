import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { EmotionStyles, flexColumn } from '@styles';

const subHeading = css({
  ...flexColumn,
  marginRight: 0,
  color: '#FFFFFF',
  marginBottom: 16,
  fontSize: 28,
});
const timerContainer = css({
  ...flexColumn,
  alignItems: 'center',
});
const timerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: -16,
});
const timerText = css({
  color: '#FFFFFF',
  fontSize: 14,
});
const timerValue = css({
  fontSize: 24,
  color: '#FFFFFF',
  // minWidth: 166,
  minWidth: 170,
});

interface LaunchCountdownTimerProps {
  customContainerStyles?: EmotionStyles;
  customTimerStyles?: EmotionStyles;
  duration: number;
  initialRemainingTime?: number;
  onComplete?: () => void;
  size?: number;
  heading?: string;
  displayCountdownTimer?: boolean;
  strokeWidth?: number;
}

export const LaunchCountdownTimer = ({
  customContainerStyles,
  customTimerStyles,
  duration,
  initialRemainingTime,
  onComplete,
  size = 200,
  heading,
  displayCountdownTimer = false,
  strokeWidth = 5,
}: LaunchCountdownTimerProps) =>
  displayCountdownTimer ? (
    <div css={[timerContainer, customContainerStyles]}>
      <Text
        css={[
          subHeading,
          {
            fontWeight: 500,
            letterSpacing: '0.24em',
            maxWidth: 320,
            textAlign: 'center',
            paddingBottom: 10,
          },
        ]}
        fontSize="16px"
        color="#FFFFFF"
      >
        {heading}
      </Text>
      <div css={customTimerStyles}>
        <CountdownCircleTimer
          isPlaying
          duration={duration}
          initialRemainingTime={initialRemainingTime}
          colors="#FFFFFF"
          // @ts-ignore
          onComplete={() => {
            onComplete?.();
            return [false, 10];
          }}
          size={size}
          strokeWidth={strokeWidth}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
    </div>
  ) : null;

interface RenderTimeProps {
  remainingTime: number;
}

function renderTime({ remainingTime }: RenderTimeProps) {
  const days = padStart(Math.floor(remainingTime / 86400));
  const hours = padStart(Math.floor((remainingTime % 86400) / 3600));
  const minutes = padStart(Math.floor((remainingTime % 3600) / 60));
  const seconds = (remainingTime % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return (
    <div css={timerStyles}>
      <p css={timerValue}>
        <span css={{ display: 'inline-block', minWidth: 29 }}>{days}</span> :{' '}
        <span css={{ display: 'inline-block', minWidth: 29 }}>{hours}</span> :{' '}
        <span css={{ display: 'inline-block', minWidth: 29 }}>{minutes}</span> :{' '}
        <span css={{ display: 'inline-block', minWidth: 29 }}>{seconds}</span>
      </p>
      <p
        css={[
          timerText,
          { display: 'flex', alignSelf: 'flex-start', minWidth: 166 },
        ]}
      >
        <span css={{ padding: '0px 2px 0px 4px', marginRight: 9 }}>DD</span>{' '}
        <span css={{ marginRight: 10 }}>:</span>
        <span css={{ marginRight: 12 }}>HR</span>{' '}
        <span css={{ marginRight: 8 }}>:</span>
        <span css={{ marginRight: 7 }}>MIN</span>{' '}
        <span css={{ marginRight: 8 }}>:</span>
        <span>SEC</span>
      </p>
    </div>
  );
}

function padStart(n: number) {
  return n < 10 ? '0' + n : n;
}
