import { ethers } from 'ethers';

export function getContractProvider<T extends ethers.Contract>(
  address: string,
  abi: ethers.ContractInterface,
) {
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return new ethers.Contract(address, abi, provider) as T;
  }
}

export function getContractSigner<T extends ethers.Contract>(
  address: string,
  abi: ethers.ContractInterface,
) {
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(address, abi, signer) as T;
  }
}
