import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { ButtonProps, Stack, Text } from '@chakra-ui/react';
import {
  MintEnabledStatus,
  useIsMintingEnabled,
  useLoserClubTokenSupply,
  useWalletMintsOfUser,
} from '@hooks';
import { RootState } from '@state';
import { EmotionStyles } from '@styles';
import { useEthers } from '@usedapp/core';
import { FaEthereum } from 'react-icons/fa';
import { useMint } from '../state';
import { MintButton } from './MintButton';
import { MintInput } from './MintInput';

type MintProps = ButtonProps & {
  customButtonStyles?: EmotionStyles;
  isIncrementDecrementCountDisplayed?: boolean;
  isWalletLoading?: boolean;
};

export const Mint = ({
  isIncrementDecrementCountDisplayed = false,
  isWalletLoading = false,
  customButtonStyles,
  ...props
}: MintProps) => {
  const isConnectedToUnsupportedChain = useSelector(
    ({ wallet }: RootState) => wallet.isConnectedToUnsupportedChain,
  );

  const { account } = useEthers();
  const mintEnabledStatus = useIsMintingEnabled();
  const { handlePublicMint, mintCountData, mintPublicData } = useMint();
  const { isSoldOut, numNftsStillAvailable, numNftsPossibleToMintInTx } =
    useLoserClubTokenSupply();
  const { numNftsWalletCanStillMint, numberMinted, isOwner } =
    useWalletMintsOfUser();

  const { mintPublicTxStatus } = mintPublicData;
  const { decrementNFTCount, incrementNFTCount, mintCount, ethRequiredToMint } =
    mintCountData;

  const displayEthRequiredCount =
    !isWalletLoading &&
    (numNftsStillAvailable !== null ||
      mintEnabledStatus === MintEnabledStatus.NoMintingAllowed ||
      !account);

  return isIncrementDecrementCountDisplayed ? (
    <Fragment>
      {!isWalletLoading && (
        <Fragment>
          <MintInput
            account={account}
            decrementNFTCount={decrementNFTCount}
            incrementNFTCount={incrementNFTCount}
            isSoldOut={isSoldOut}
            isConnectedToUnsupportedChain={isConnectedToUnsupportedChain}
            isWalletLoading={isWalletLoading}
            mintEnabledStatus={mintEnabledStatus}
            mintPublicTxStatus={mintPublicTxStatus}
            mintCount={mintCount}
            numNftsStillAvailable={numNftsStillAvailable}
            numNftsPossibleToMintInTx={numNftsPossibleToMintInTx}
            numNftsWalletCanStillMint={numNftsWalletCanStillMint}
            numberMinted={numberMinted}
            isOwner={isOwner}
          />
          {displayEthRequiredCount ? (
            <Text
              textAlign="center"
              fontSize="20px"
              color="white"
              backgroundColor="rgba(255, 99, 173, 0.00)"
              fontWeight="600"
              letterSpacing={isSoldOut ? '0.14em' : '0.2em'}
              marginTop="10px"
              padding={isSoldOut ? '12px 24px 0px' : '8px 24px 0px'}
              borderRadius="2xl"
              display="flex"
              alignItems="center"
              position="relative"
            >
              <FaEthereum
                css={{
                  display: 'flex',
                  verticalAlign: 'middle',
                  marginRight: 8,
                }}
                color="white"
              />{' '}
              <span css={{ marginRight: 6 }}>ETH:</span>{' '}
              <span css={{ minWidth: 58 }}>{ethRequiredToMint}</span>
            </Text>
          ) : null}
          <Stack
            padding={{ base: '12px 28px 28px 28px', lg: undefined }}
            direction="row"
            spacing={4}
            justifyItems="flex-end"
            marginTop="0"
          >
            <MintButton
              account={account}
              handlePublicMint={handlePublicMint}
              isSoldOut={isSoldOut}
              isConnectedToUnsupportedChain={isConnectedToUnsupportedChain}
              isWalletLoading={isWalletLoading}
              mintEnabledStatus={mintEnabledStatus}
              mintPublicTxStatus={mintPublicTxStatus}
              mintCount={mintCount}
              numNftsStillAvailable={numNftsStillAvailable}
              numNftsPossibleToMintInTx={numNftsPossibleToMintInTx}
              numNftsWalletCanStillMint={numNftsWalletCanStillMint}
              isOwner={isOwner}
              numberMinted={numberMinted}
              customButtonStyles={customButtonStyles}
              {...props}
            />
          </Stack>
        </Fragment>
      )}
    </Fragment>
  ) : (
    <Fragment>
      {!isWalletLoading && (
        <MintButton
          account={account}
          handlePublicMint={handlePublicMint}
          isSoldOut={isSoldOut}
          isConnectedToUnsupportedChain={isConnectedToUnsupportedChain}
          isWalletLoading={isWalletLoading}
          mintEnabledStatus={mintEnabledStatus}
          mintPublicTxStatus={mintPublicTxStatus}
          mintCount={mintCount}
          numNftsStillAvailable={numNftsStillAvailable}
          numNftsPossibleToMintInTx={numNftsPossibleToMintInTx}
          numNftsWalletCanStillMint={numNftsWalletCanStillMint}
          numberMinted={numberMinted}
          isOwner={isOwner}
          customButtonStyles={customButtonStyles}
          {...props}
        />
      )}
    </Fragment>
  );
};
