import { DevoptixLogo, DevoptixText } from '@assets';
import { Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { EmotionStyles } from '@styles';
import { IconCustom } from './IconCustom';
import { SocialIconButton } from './SocialIconButton';

interface BuiltByDevoptixProps {
  fontSize?: string | number;
  customContainerStyles?: EmotionStyles;
  isMobile?: boolean;
}

const containerStyles = (isMobile?: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 10px 4px 9px',
    position: 'absolute',
    right: isMobile ? 22 : 30,
    bottom: isMobile ? 20 : 10,
    '&:hover': {
      transition: 'opacity 0.1s ease-in-out',
      opacity: '0.7',
    },
    '&:active': {
      background: 'rgba(27, 27, 27, 0.9)',
    },
  });
const rowItemStyles = css({
  display: 'flex',
  flexDirection: 'row',
});

export const BuiltByDevoptix = ({
  fontSize,
  customContainerStyles,
  isMobile,
}: BuiltByDevoptixProps) => (
  <div css={[containerStyles(isMobile), customContainerStyles]}>
    <div css={[rowItemStyles, { marginBottom: '-12px' }]}>
      <Text
        color="#FFFFFF"
        fontSize={10}
        fontWeight="400"
        letterSpacing={'0.5em'}
      >
        BUILT BY
      </Text>
    </div>
    <div css={[rowItemStyles, { marginBottom: '-14px' }]}>
      <SocialIconButton
        aria-label="Devoptix"
        href="https://twitter.com/devoptix"
        target="_blank"
        icon={
          <IconCustom
            src={DevoptixText}
            alt="Devoptix"
            aria-label="Devoptix"
            noHoverStyles
          />
        }
        fontSize={fontSize ?? 100}
        filter="brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(172deg) brightness(105%) contrast(101%)"
        marginRight="-4px"
        _focus={{ boxShadow: 'none' }}
        overflow="hidden"
        noHoverStyles
      />
      <SocialIconButton
        aria-label="Devoptix"
        href="https://twitter.com/devoptix"
        target="_blank"
        icon={
          <IconCustom
            src={DevoptixLogo}
            alt="Devoptix"
            aria-label="Devoptix"
            noHoverStyles
          />
        }
        fontSize={16}
        filter="brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(172deg) brightness(105%) contrast(101%)"
        marginRight="-18px"
        _focus={{ boxShadow: 'none' }}
        overflow="hidden"
        _hover={{ color: 'pink' }}
      />
    </div>
  </div>
);
