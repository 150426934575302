import { Fragment } from 'react';
import { EtherscanLogo, LooksRare, OpenSeaLogo } from '@assets';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Divider,
  Flex,
  FlexProps,
  IconButton,
  ScaleFade,
  useDisclosure,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useIsMobile } from '@hooks';
import { FlexProperties } from '@models';
import { EmotionStyles } from '@styles';
import { BiShareAlt } from 'react-icons/bi';
import { BsMedium, BsTwitter } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { IconCustom } from './IconCustom';
import { SocialIconButton } from './SocialIconButton';

type SocialsLinksBadgeProps = FlexProps & {
  justifyContent?: FlexProperties;
  fontSize?: string | number;
  width?: string;
  background?: string;
  customContainerStyles?: EmotionStyles;
};

const verticalColumnContainerStyles = (isMobile: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: isMobile ? 4 : 6,
  });
const verticalColumnItemStyles = css({
  margin: 'auto',
});

export const SocialsLinksBadge = ({
  justifyContent,
  fontSize,
  width,
  background,
  customContainerStyles,
  ...props
}: SocialsLinksBadgeProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const isMobile = useIsMobile();

  return (
    <Flex
      alignItems="center"
      justifyContent={justifyContent}
      width={width}
      background={background}
      css={[
        verticalColumnContainerStyles(isMobile),
        customContainerStyles,
        { borderRadius: isMobile ? '80px' : undefined },
      ]}
      {...props}
    >
      {isMobile ? (
        <Fragment>
          <IconButton
            aria-label="View Social Media Accounts"
            icon={isOpen ? <ChevronDownIcon /> : <BiShareAlt />}
            onClick={onToggle}
            fontSize={isOpen ? '30px' : '26px'}
            background="transparent"
            color="rgba(107, 70, 193, 0.70)"
            borderRadius="80px"
          />
          <ScaleFade initialScale={0.9} in={isOpen}>
            {isOpen ? (
              <Fragment>
                {/* TODO: comment back in once these sites exist / are opened to public  */}
                {/* <SocialIconButton
                  aria-label="Join the Loser Club discord"
                  // TODO: need invite link, not admin on their discord
                  href="https://discord.com/"
                  target="_blank"
                  icon={<FaDiscord />}
                  fontSize={fontSize}
                  css={verticalColumnItemStyles}
                />
                <Divider color="transparent" margin="4px 0px" width="90%" /> */}
                <SocialIconButton
                  aria-label="View collection on OpenSea"
                  href="https://opensea.io/collection/loser-club-official"
                  target="_blank"
                  icon={
                    <IconCustom
                      src={OpenSeaLogo}
                      alt="Available on OpenSea"
                      aria-label="Available on OpenSea"
                    />
                  }
                  fontSize={fontSize}
                  css={verticalColumnItemStyles}
                />
                <Divider color="transparent" margin="4px 0px" width="90%" />
                <SocialIconButton
                  aria-label="Check us out on twitter"
                  href="https://twitter.com/loserclubreborn"
                  target="_blank"
                  icon={<BsTwitter />}
                  fontSize={fontSize}
                  css={verticalColumnItemStyles}
                />
                <Divider color="transparent" margin="4px 0px" width="90%" />
                <SocialIconButton
                  aria-label="Learn more about The Loser Club on Medium"
                  href="https://lawpez.medium.com/nft-community-its-time-we-stopped-undervaluing-the-artists-45f5effdd27"
                  target="_blank"
                  icon={<BsMedium />}
                  fontSize={fontSize}
                  css={verticalColumnItemStyles}
                />
                <Divider color="transparent" margin="4px 0px" width="90%" />
                <SocialIconButton
                  aria-label="View collection on LooksRare"
                  href="https://looksrare.org/collections/0x709d30f1f60F03D85A0EF33142eF3259392dC9e1"
                  target="_blank"
                  icon={
                    <IconCustom
                      src={LooksRare}
                      alt="Available on LooksRare"
                      aria-label="Available on LooksRare"
                      filter="brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(1595%) hue-rotate(196deg) brightness(115%) contrast(100%)"
                    />
                  }
                  fontSize={fontSize}
                  css={verticalColumnItemStyles}
                />
                <Divider color="transparent" margin="4px 0px" width="90%" />
                <SocialIconButton
                  aria-label="View contract on Etherscan"
                  href="https://etherscan.io/address/0x709d30f1f60f03d85a0ef33142ef3259392dc9e1#code"
                  target="_blank"
                  icon={
                    <IconCustom
                      src={EtherscanLogo}
                      alt="View contract on Etherscan"
                      aria-label="View contract on Etherscan"
                    />
                  }
                  fontSize={fontSize}
                  css={verticalColumnItemStyles}
                />
              </Fragment>
            ) : null}
          </ScaleFade>
        </Fragment>
      ) : (
        <Fragment>
          {/* TODO: comment back in once these sites exist / are opened to public  */}
          {/* <SocialIconButton
            aria-label="Join our discord"
            href="https://discord.com/"
            target="_blank"
            icon={<FaDiscord />}
            fontSize={fontSize}
            css={verticalColumnItemStyles}
          />
          <Divider color="transparent" margin="4px 0px" width="90%" /> */}
          <SocialIconButton
            aria-label="View collection on OpenSea"
            href="https://opensea.io/collection/loser-club-official"
            target="_blank"
            icon={
              <IconCustom
                src={OpenSeaLogo}
                alt="Available on OpenSea"
                aria-label="Available on OpenSea"
              />
            }
            fontSize={fontSize}
            css={verticalColumnItemStyles}
          />
          <Divider color="transparent" margin="4px 0px" width="90%" />
          <SocialIconButton
            aria-label="Check us out on twitter"
            href="https://twitter.com/loserclubreborn"
            target="_blank"
            icon={<BsTwitter />}
            fontSize={fontSize}
            css={verticalColumnItemStyles}
          />
          <Divider color="transparent" margin="4px 0px" width="90%" />
          <SocialIconButton
            aria-label="Learn more about The Loser Club on Medium"
            href="https://lawpez.medium.com/nft-community-its-time-we-stopped-undervaluing-the-artists-45f5effdd27"
            target="_blank"
            icon={<BsMedium />}
            fontSize={fontSize}
            css={verticalColumnItemStyles}
          />
          <Divider color="transparent" margin="4px 0px" width="90%" />
          <SocialIconButton
            aria-label="View collection on LooksRare"
            href="https://looksrare.org/collections/0x709d30f1f60F03D85A0EF33142eF3259392dC9e1"
            target="_blank"
            icon={
              <IconCustom
                src={LooksRare}
                alt="Available on LooksRare"
                aria-label="Available on LooksRare"
                filter="brightness(0) saturate(100%) invert(100%) sepia(7%) saturate(1595%) hue-rotate(196deg) brightness(115%) contrast(100%)"
              />
            }
            fontSize={fontSize}
            css={verticalColumnItemStyles}
          />
          <Divider color="transparent" margin="4px 0px" width="90%" />
          <SocialIconButton
            aria-label="View contract on Etherscan"
            href="https://etherscan.io/address/0x709d30f1f60f03d85a0ef33142ef3259392dc9e1#code"
            target="_blank"
            icon={
              <IconCustom
                src={EtherscanLogo}
                alt="View contract on Etherscan"
                aria-label="View contract on Etherscan"
              />
            }
            fontSize={fontSize}
            css={verticalColumnItemStyles}
          />
        </Fragment>
      )}
    </Flex>
  );
};
