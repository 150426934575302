import { EtherscanLogo, LooksRare, OpenSeaLogo } from '@assets';
import { Divider, Flex, FlexProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { FlexProperties } from '@models';
import { EmotionStyles } from '@styles';
import { BsTwitter } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { IconCustom } from './IconCustom';
import { SocialIconButton } from './SocialIconButton';

type SocialsLinksProps = FlexProps & {
  justifyContent?: FlexProperties;
  fontSize?: string | number;
  width?: string;
  background?: string;
  customContainerStyles?: EmotionStyles;
  isVerticalLayout?: boolean;
};

const verticalColumnContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 6,
});
const verticalColumnItemStyles = css({
  margin: 'auto',
});

export const SocialsLinks = ({
  justifyContent,
  fontSize,
  width,
  background,
  customContainerStyles,
  isVerticalLayout = false,
  ...props
}: SocialsLinksProps) => (
  <Flex
    alignItems="center"
    justifyContent={justifyContent}
    width={width}
    background={background}
    css={[
      isVerticalLayout ? verticalColumnContainerStyles : undefined,
      customContainerStyles,
    ]}
    {...props}
  >
    <SocialIconButton
      aria-label="Join our discord"
      href="https://discord.com/"
      target="_blank"
      icon={<FaDiscord />}
      fontSize={fontSize}
      css={isVerticalLayout ? verticalColumnItemStyles : undefined}
    />
    {isVerticalLayout && (
      <Divider color="transparent" margin="4px 0px" width="90%" />
    )}
    <SocialIconButton
      aria-label="View collection on OpenSea"
      href="https://opensea.io/"
      target="_blank"
      icon={
        <IconCustom
          src={OpenSeaLogo}
          alt="Available on OpenSea"
          aria-label="Available on OpenSea"
        />
      }
      fontSize={fontSize}
      css={isVerticalLayout ? verticalColumnItemStyles : undefined}
    />
    {isVerticalLayout && (
      <Divider color="transparent" margin="4px 0px" width="90%" />
    )}
    <SocialIconButton
      aria-label="View collection on LooksRare"
      href="https://looksrare.org/"
      target="_blank"
      icon={
        <IconCustom
          src={LooksRare}
          alt="Available on LooksRare"
          aria-label="Available on LooksRare"
        />
      }
      fontSize={fontSize}
      css={isVerticalLayout ? verticalColumnItemStyles : undefined}
    />
    {isVerticalLayout && (
      <Divider color="transparent" margin="4px 0px" width="90%" />
    )}
    <SocialIconButton
      aria-label="View contract on Etherscan"
      href="https://etherscan.io/"
      target="_blank"
      icon={
        <IconCustom
          src={EtherscanLogo}
          alt="View contract on Etherscan"
          aria-label="View contract on Etherscan"
        />
      }
      fontSize={fontSize}
      css={isVerticalLayout ? verticalColumnItemStyles : undefined}
    />
    {isVerticalLayout && (
      <Divider color="transparent" margin="4px 0px" width="90%" />
    )}
    <SocialIconButton
      aria-label="Check us out on twitter"
      href="https://twitter.com/loserclubreborn"
      target="_blank"
      icon={<BsTwitter />}
      fontSize={fontSize}
      css={isVerticalLayout ? verticalColumnItemStyles : undefined}
    />
  </Flex>
);
