import { LoserClubABI } from '@abis';
import { useContractCall } from '@usedapp/core';
import { getCurrentTimeInSeconds } from 'src/components/Dates/utils';
import { useGetCurrentContractAddress } from './useGetCurrentContractAddress';

export enum MintEnabledStatus {
  NoMintingAllowed = 'No Minting Allowed',
  AllowlistMintingEnabled = 'Allowlist Minting Enabled',
  PublicMintingEnabled = 'Public Minting Enabled',
}

/**
 * Hook used to retrieve current mint status (Public, Allowlist, or No Minting Allowed)
 */
export function useIsMintingEnabled() {
  const currentContractAddress = useGetCurrentContractAddress();

  const [isAllowlistMintEnabled]: any =
    useContractCall({
      abi: LoserClubABI,
      address: currentContractAddress,
      method: 'isAllowlistMintEnabled',
      args: [],
    }) ?? [];

  const [alSaleTime]: any =
    useContractCall({
      abi: LoserClubABI,
      address: currentContractAddress,
      method: 'allowlistSaleTime',
      args: [],
    }) ?? [];

  const allowlistSaleTime = alSaleTime
    ? Number(alSaleTime.toString())
    : Infinity;

  const [isPublicMintEnabled]: any =
    useContractCall({
      abi: LoserClubABI,
      address: currentContractAddress,
      method: 'isPublicMintEnabled',
      args: [],
    }) ?? [];

  const [pubSaleTime]: any =
    useContractCall({
      abi: LoserClubABI,
      address: currentContractAddress,
      method: 'publicSaleTime',
      args: [],
    }) ?? [];

  const publicSaleTime = pubSaleTime
    ? Number(pubSaleTime.toString())
    : Infinity;

  const currentTime = getCurrentTimeInSeconds();

  if (isPublicMintEnabled && currentTime >= publicSaleTime) {
    return MintEnabledStatus.PublicMintingEnabled;
  }
  if (
    isAllowlistMintEnabled &&
    !isPublicMintEnabled &&
    currentTime >= allowlistSaleTime
  ) {
    return MintEnabledStatus.AllowlistMintingEnabled;
  }
  return MintEnabledStatus.NoMintingAllowed;
}
