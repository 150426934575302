import { Progress } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { Size } from '@models';
import { EmotionStyles } from '@styles';

const loadingStyles = (isSmallMobile: boolean) =>
  css({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: isSmallMobile ? 180 : 228,
  });

interface ProgressBarProps {
  customContainerStyles?: EmotionStyles;
  size?: Size;
  isSmallMobile: boolean;
}

export const ProgressBar = ({
  customContainerStyles,
  isSmallMobile,
  size = 'xs',
}: ProgressBarProps) => (
  <div css={[loadingStyles(isSmallMobile), customContainerStyles]}>
    <Progress
      size={size}
      isIndeterminate
      borderTopRadius="xl"
      marginBottom="1.5"
      colorScheme="loserClub"
      backgroundColor="white"
    />
    <Progress
      size={size}
      isIndeterminate
      marginBottom="1.5"
      colorScheme="loserClub"
      backgroundColor="white"
    />
    <Progress
      size={size}
      isIndeterminate
      marginBottom="1.5"
      colorScheme="loserClub"
      backgroundColor="white"
    />
    <Progress
      size={size}
      isIndeterminate
      marginBottom="1.5"
      colorScheme="loserClub"
      backgroundColor="white"
    />
    <Progress
      size={size}
      isIndeterminate
      borderBottomRadius="xl"
      colorScheme="loserClub"
      backgroundColor="white"
    />
  </div>
);
