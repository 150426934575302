import { Progress, Stack } from '@chakra-ui/react';

export const ProgressBarMintButton = () => (
  <Stack
    direction="column"
    spacing={4}
    justifyItems="flex-end"
    width="40%"
    marginTop={-20}
    marginBottom="50px"
  >
    <Progress
      height="16px"
      isIndeterminate
      borderTopRadius="xl"
      hasStripe
      colorScheme="loserClub"
      backgroundColor="white"
    />
    <Progress
      size="xs"
      isIndeterminate
      hasStripe
      colorScheme="loserClub"
      backgroundColor="white"
    />
    <Progress
      size="xs"
      isIndeterminate
      hasStripe
      colorScheme="loserClub"
      backgroundColor="white"
    />
    <Progress
      size="xs"
      isIndeterminate
      hasStripe
      colorScheme="loserClub"
      backgroundColor="white"
    />
    <Progress
      height="20px"
      isIndeterminate
      borderBottomRadius="xl"
      hasStripe
      colorScheme="loserClub"
      backgroundColor="white"
    />
  </Stack>
);
